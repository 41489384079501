import React from "react";
import {
  Card,
  CardBody,
  CardTitle
} from "reactstrap";

import ChartComponent, { Chart } from "react-chartjs-2";
import { ThemeColors } from '../../helpers/ThemeColors';
import { chartTooltip } from '../../components/charts/util';
const colors = ThemeColors();

const barChartOptions = {
	legend: false,
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		yAxes: [
		  {
			gridLines: {
			  display: true,
			  lineWidth: 1,
			  color: 'rgba(0,0,0,0.1)',
			  drawBorder: false
			},
			ticks: {
			  beginAtZero: true,
			  min: 100,
			  padding: 20
			}
		  }
		],
		xAxes: [
		  {
			gridLines: {
			  display: false
			}
		  }
		]
	},
	tooltips: chartTooltip
}

class BarChart extends React.Component {
	componentWillMount() {
		if (this.props.shadow) {
		  Chart.defaults.barWithShadow = Chart.defaults.bar;
		  Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
			draw: function(ease) {
			  Chart.controllers.bar.prototype.draw.call(this, ease);
			  var ctx = this.chart.ctx;
			  ctx.save();
			  ctx.shadowColor = "rgba(0,0,0,0.2)";
			  ctx.shadowBlur = 7;
			  ctx.shadowOffsetX = 5;
			  ctx.shadowOffsetY = 7;
			  ctx.responsive = true;
			  Chart.controllers.bar.prototype.draw.apply(this, arguments);
			  ctx.restore();
			}
		  });
		}
	}

	render() {
		const { data, shadow } = this.props;
		return (
		  <ChartComponent
			ref={ref => (this.chart_instance = ref && ref.chart_instance)}
			type={shadow ? "barWithShadow" : "bar"}
			options={{
			  ...barChartOptions
			}}
			data={data}
		  />
		);
	}
}

const EarningGraph = ({ data }) => {	
	
	let graph_data={
		'JAN':0,
		'FEB':0,
		'MAR':0,
		'APR':0,
		'MAY':0,
		'JUN':0,
		'JUL':0,
		'AUG':0,
		'SEP':0,
		'OCT':0,
		'NOV':0,
		'DEC':0		
	};
	for(let key in data){
		if(typeof data[key]!='undefined' && key!="total_sales"){
			graph_data[key]=data[key];
		}
	}
	
	
    const barChartData = {
	  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
	  datasets: [
		{
		  label: 'Sales',
		  borderColor: colors.themeColor1,
		  backgroundColor: colors.themeColor1_10,
		  data: [...Object.values(graph_data)],
		  borderWidth: 2
		}
	  ]
	}
	
	return(
		<Card style={{"height": "550px"}}>
			<CardBody>
				<CardTitle>
					Earning Graph
				</CardTitle>
				<div style={{"height": "450px"}}>
					<BarChart shadow={false} data={barChartData} />
				</div>
			</CardBody>
		</Card>
	);
}

export default EarningGraph;
