import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge,  DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddNewVehicleModal from "../../../containers/customer/vehicles/AddNewVehicleModal";
import EditVehicleModal from "../../../containers/customer/vehicles/EditVehicleModal";
import ReactTable from "react-table";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Vehicles extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenAssign: false,
			vehicles:[],
			customers:[],
			vehicle_id:0,
			search: '',
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,vehicle_id=0) => {
		this.setState({
			vehicle_id: parseInt(vehicle_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
		
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.vehicles.forEach(x => {
                newSelected.push(x.vehicle_id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
  
    componentDidMount(){
	   this.dataListRender();
	}
  
    deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="vehicle";
		
		if(selectedIds.length>1){
			msg_word="vehicles";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-vehicles").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('vehicle_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'customer/delete-vehicles',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-vehicles").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-vehicles").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
	
	activeItem=(e,vehicle_ids)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(vehicle_ids);
			
		}else{
		  var x=selectedIds.indexOf(vehicle_ids);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.vehicles.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'customer/get-all-vehicles',formData).then((res)=>{
			let data = res.data;
			let customers = data.customers;
			customers = customers.map(row=>{
				row.label=row.name;
				row.value=row.id;
				row.key=row.id;
				return row;
			});
			
			if(data.vehicles){
				this.setState({
					vehicles: data.vehicles,
					selectAll:0,
					selectedIds:[],
					customers,
				});
			}else{
				this.setState({
					vehicles: [],
					selectAll:0,
					selectedIds:[],
					customers
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {
		
		let data = this.state.vehicles
		if (this.state.search) {
			data = data.filter(row => {
				return row.customer_name.toLowerCase().includes(this.state.search) || row.emirate.toLowerCase().includes(this.state.search) || row.vehicle_make.toLowerCase().includes(this.state.search) || row.vehicle_model.toLowerCase().includes(this.state.search) || row.no_plate.toLowerCase().includes(this.state.search) || row.vehicle_year.toLowerCase().includes(this.state.search)
			})
		}
		
	const dataTableColumns = [ 
	
		{
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "vehicle_id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {				
				return ( <FormGroup>
				{parseInt(rowInfo.original.order_count)==0 ? <CustomInput type="checkbox" id={"vehicle_"+rowInfo.original.vehicle_id} onChange={event =>this.activeItem(event,rowInfo.original.vehicle_id) } checked={this.state.selectedIds.includes(rowInfo.original.vehicle_id)} />:""}
					</FormGroup> );				
			}
		},		
		{
			Header: "Customer Name",
			accessor: "customer_name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Number",
			accessor: "no_plate",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Emirate",
			accessor: "emirate",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Brand Name",
			accessor: "vehicle_make",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Model Name",
			accessor: "vehicle_model",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Year",
			accessor: "vehicle_year",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Actions",
			accessor: "vehicle_id",
			sortable: false,
			Cell: ( rowInfo )=> {
					return (<Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
					<DropdownItem onClick={(e)=> this.toggleModalEdit(e,rowInfo.value) } >					
						<span className="d-inline-block">Edit</span>															
					</DropdownItem>
					{parseInt(rowInfo.original.order_count)==0 ? <DropdownItem onClick={(e)=> this.deleteItem(e,[rowInfo.value],2) } >					
						<span className="d-inline-block">Delete</span>															
					</DropdownItem>:<DropdownItem><span className="text-danger d-inline-block">Order is in progress</span></DropdownItem>}
				  </DropdownMenu>} /></div></Fragment>) 
				  }
			  
		}
	];
	
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading="sidebar.vehicles" match={this.props.match} />
				<Separator className="mb-5" />
			</Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  <Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx> 
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  <IntlMessages id="pages.add-new" />							  
						</Button>{" "}<Button
								id="delete-vehicles"
								color="primary"
								size="sm"
								className=""
                                onClick={event =>this.deleteItem(event,this.state.selectedIds,2)}								
							>
								<IntlMessages id="pages.delete" />
						</Button>
					</div>
					 
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>					
			  </Colxx>
			</Row>
			<AddNewVehicleModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
				customers={this.state.customers}
			/>
			<EditVehicleModal
			    modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				vehicle_id={this.state.vehicle_id}
				dataListRender={this.dataListRender}
				customers={this.state.customers}
			/>
		</Card>
      </Fragment>
    );
  }
}
