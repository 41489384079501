import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from 'moment';
import DatePicker from "../../components/common/CustomDateTimePicker";
import swal from "sweetalert";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Select from "react-select";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddNewAccountsModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {		
			startDate:"",
			selectCategory:[],		
			selectCategoryType:null,
			selectStaff:[],
			selectStaffType:null,
		}
	}
	componentDidMount() {		
		var category=[];
		var staff=[]
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'expenses/get-all-data-variable-expense',formData).then((res)=>{
			var data = res.data;
		
			staff=data.staffs;
			
			
			staff = staff.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			category=data.categories;
			
			
			category = category.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			
			
			this.setState({
				selectCategory:category,
				selectCategoryType:category[0],
				selectStaff:staff,
				
				
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			if(this.state.startDate.length==0){
				swal("Warning!","Please select date","warning");
				return;
			}
			document.getElementById("add-account").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			if(!this.state.selectCategoryType){
				swal("Warning!","Please add  a type in data variable module","warning");
				return;
			}
			var category = this.state.selectCategoryType;
			
			
			var formData = new FormData(document.getElementById("accountForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('data_variable',category.id)
			if(this.state.selectStaffType)
			{ formData.append('staffId',this.state.selectStaffType.id)}
			// axios.post(REST_API_END_POINT+'account/add-account',formData).then((res)=>{
				axios.post(REST_API_END_POINT+'expenses/add-expense',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
				}
				
				document.getElementById("add-account").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				this.setState({startDate: null,
				})

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-account").disabled=false;
			});

		}
	}
	
  render() {	
	const {  selectCategory,selectStaff} = this.state;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Expense
		  </ModalHeader>
		  <AvForm
		         id="accountForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required"
							  },
							//   pattern: {
							// 	value: "^[a-zA-Z0-9 ,.'-]+$",
							// 	errorMessage: "Invalid name"
							//   },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Amount<span className="text-danger">*</span></Label>
						  <AvField
							id="amount"
							name="amount"
							type="number"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required"
							  },
							  min:{
								value: 0,
								errorMessage: "Please enter a value greater than  0"
							}
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
				  <Colxx lg="12">
			      <Label>Date<span className="text-danger">*</span></Label>
					<DatePicker
								id="dob"
								name="entryDate"
								autoComplete="off"
								className="mb-5"
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								maxDate={this.props.week_end!=null ? moment(this.props.week_end):moment()}
								minDate={this.props.week_start!=null ? moment(this.props.week_start):null}
								
								placeholderText=""
								dateFormat="YYYY-MM-DD"
								
						/>
						</Colxx>
				</Row>
				<Row >
					<Colxx lg="12 ">
						<Label >Type<span className="text-danger">*</span></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							id="category_id"
							name="category_id"
							value={this.state.selectCategoryType }
							onChange={selectCategoryType => { this.setState({ selectCategoryType }) }}
							options={selectCategory}
						/>
					</Colxx>									
				</Row>
				<br></br>
				<br></br>
				<Row>
				<Colxx lg="12 ">
					<Label >Choose staff</Label>
					<Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						id="staff_id"
						name="staff_id"
						value={this.state.selectStaffType }
						onChange={selectStaffType => { this.setState({ selectStaffType }) }}
						options={selectStaff}
						/>
					</Colxx>	
				</Row>
				
			</ModalBody>
			<ModalFooter>
				<Button color="primary" id="add-account" >
				  <IntlMessages id="customer.add" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
				  <IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
