import React, { Component,Fragment } from "react";
import { connect } from "react-redux";
import { NavItem, Badge,Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import IntlMessages from "../../helpers/IntlMessages";
import ApplicationMenu from "../../components/common/ApplicationMenu";
import { getCustomerWord } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

export default class CheckedInMenu extends Component {
	intervalId=null;
	
	constructor(props) {
		super();
		this.state = {
			customers:[]
		}
		this.dataListRender = this.dataListRender.bind(this);
		this.checkoutAllCustomer = this.checkoutAllCustomer.bind(this);
	}
	
	componentDidMount(){
		this.dataListRender();
		this.intervalId = setInterval(this.dataListRender, 10000);
	}
	
	componentWillUnmount() {
		clearInterval(this.intervalId);
	}
	
	dataListRender() {		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-checked-in-customers',formData).then((res)=>{
			var data = res.data;
					
			if(data.customers){
				var customers=data.customers;				
				this.setState({
			        customers,				
				});
			}else{
				this.setState({
					customers: []
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	checkoutAllCustomer(){
		swal({
          title: "Are you sure?",
          text: "Checkout all "+customerWord.toLowerCase()+"s",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'point-of-sale/checkout-all-customers',formData).then((res)=>{
					var data = res.data;

					this.dataListRender();
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}else{
				swal("Your operation is canceled!");
			}
		});
	}

	render() {
		const checked_in_customer = this.state.customers.filter(row=>{ return row.hypur_isn=="" });
		const checked_in_hypur = this.state.customers.filter(row=>{ return row.hypur_isn!="" });
		return (
			<ApplicationMenu>
				{this.state.customers.length ? <div className="text-center mt-4">	
					<Button
					  color="primary"
					  className=""
					  onClick={this.checkoutAllCustomer}
					>
					  {" "}Checkout All {customerWord}s
					</Button>
				</div>:""}
				
				<PerfectScrollbar
				  option={{ suppressScrollX: true, wheelPropagation: false }}
				>
					<div className="p-4">
						<h3 className="font-weight-bold">
							Checked In {customerWord}s
						</h3>
						{checked_in_customer.length==0 ? <div className="text-left mt-2 text-muted">	
							No checked in {customerWord}s
						</div>:<ul className="list-unstyled section-list">
							{checked_in_customer.map((row,index)=>{
								return(<li className="section-li cursor-pointer" key={index}>
									<a href={"/app/point-of-sale/pos/"+row.vendor_id+'/'+row.check_in_vehicle_id} className= "d-inline-block">
										<img src={row.profile_pic} className="checked-in-avatar" />	
										<span className="section-name">{row.vendor_name}({row.checkintime})</span>	
									</a>
								</li>)})
							}
						</ul>}								
					</div>
					{/*<div className="pl-4 pr-4 pb-4 pt-2">
						<h3 className="font-weight-bold">
							Checked In Hypur Distributers
						</h3>
						{checked_in_hypur.length==0 ? <div className="text-left mt-2 text-muted">	
							No checked in hypur distributers
						</div>:<ul className="list-unstyled section-list">
							{checked_in_hypur.map((row,index)=>{
								return(<li className="section-li cursor-pointer" key={index}>
									<a href={"/app/point-of-sale/pos/"+row.vendor_id} className= "d-inline-block">
										<img src={row.profile_pic} className="checked-in-avatar" />	
										<span className="section-name">{row.vendor_name}({row.checkintime})</span>	
									</a>
								</li>)})
							}
						</ul>}                    			
					</div>*/}
				</PerfectScrollbar>
			</ApplicationMenu>
		);
	}
}
