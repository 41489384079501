
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { REST_API_END_POINT,dataEncryptKey } from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";

import {
    LOGIN_USER,
	LOGIN_AS_SUBSCRIBER,
    //REGISTER_USER,
    LOGOUT_USER
} from '../actions';


import {
    loginUserSuccess,
    registerUserSuccess
} from './actions';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const loginWithEmailPasswordAsync = async (login_data) =>{
	var form_data = new FormData();
	for(let key in login_data){
		form_data.append(key,login_data[key]);
	}
	return axios.post(REST_API_END_POINT+'register/login',form_data).then((data)=>{		
		return data.data;
	}).catch(e => console.log("Addition failed , Error ", e));
}


function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, payload.user);
		
        if (loginUser.status===1) {
			var user_data={};
			user_data.first_name=loginUser.user_data.first_name;
			user_data.last_name=loginUser.user_data.last_name;
			user_data.user_type=loginUser.user_data.user_type;
			user_data.profile_pic=loginUser.user_data.profile_pic;
			user_data.subscriber_id=loginUser.user_data.subscriber_id;			
			
			if(typeof loginUser.user_data.facilities!=='undefined' && loginUser.user_data.facilities!==null){
				var facility_id = localStorage.getItem('facility_id');
				
				user_data.facilities=loginUser.user_data.facilities;
				if(typeof facility_id=='undefined' || facility_id==null){
					localStorage.setItem('facility_id', dataCrypto.encrypt(user_data.facilities[0].id));
				}else{
					facility_id =  dataCrypto.decrypt(facility_id);
				}
				
				var form_data = new FormData();
				form_data.append("facility_id",facility_id);
				
				axios.post(REST_API_END_POINT+'register/get-facility-data',form_data).then((data)=>{					
					if(data.data.status==1){
						localStorage.setItem('facility_data', dataCrypto.encrypt(data.data.facility_data));
					}					
				}).catch(e => console.log("Addition failed , Error ", e));
			}
			
			localStorage.setItem('user_id', loginUser.user_data.id);
			localStorage.setItem('subscriber_id', user_data.subscriber_id);
            localStorage.setItem('user_data', JSON.stringify(user_data));
			if(user_data.user_type==="2"){
				localStorage.setItem('login_admin', 'is_admin');
			}else if(user_data.user_type==="17"){
				localStorage.setItem('shop_customer_id', loginUser.user_data.customer_id);
			}
            yield put(loginUserSuccess(loginUser));
			if(user_data.user_type!=="2" && user_data.user_type!=="17"){
				history.push('/app');
			}else if(user_data.user_type==="17"){			
				history.push('/shop');
			}else{				
				history.push('/administrator');
			}
            
        } else {
			swal(" ",loginUser.message, "error");
            console.log('login failed :', loginUser.message)
        }
    } catch (error) {
        console.log('login error : ', error)
    }
}

const loginWithUserIdAsync = async (login_data) =>{
	var form_data = new FormData();
	for(let key in login_data){
		form_data.append(key,login_data[key]);
	}
	return axios.post(REST_API_END_POINT+'/register/get-user-data',form_data).then((res)=>{ 
		return res.data;
	}).catch(e => console.log("Addition failed , Error ", e));
}


function* loginWithUserId({ payload }) {
    const { user_id,from_id } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithUserIdAsync, payload.user);
		
        if (loginUser.status===1) {
			var user_data={};
			user_data.first_name=loginUser.user_data.first_name;
			user_data.last_name=loginUser.user_data.last_name;
			user_data.user_type=loginUser.user_data.user_type;
			user_data.profile_pic=loginUser.user_data.profile_pic;
			user_data.subscriber_id=loginUser.user_data.subscriber_id;			
			
			if(typeof loginUser.user_data.facilities!=='undefined' && loginUser.user_data.facilities!==null){
				user_data.facilities=loginUser.user_data.facilities;
				localStorage.setItem('facility_id', dataCrypto.encrypt(user_data.facilities[0].id));
				
				var form_data = new FormData();
				form_data.append("facility_id",user_data.facilities[0].id);
				
				form_data.append('user_id',user_id)
				axios.post(REST_API_END_POINT+'register/get-facility-data',form_data).then((data)=>{					
					if(data.data.status==1){
						localStorage.setItem('facility_data', dataCrypto.encrypt(data.data.facility_data));
					}					
				}).catch(e => console.log("Addition failed , Error ", e));
			}
			
			localStorage.setItem('user_id', loginUser.user_data.id);
			localStorage.setItem('subscriber_id', user_data.subscriber_id);
            localStorage.setItem('user_data', JSON.stringify(user_data));
			if(user_data.user_type==="2"){
				localStorage.setItem('login_admin', 'is_admin');
			}
            yield put(loginUserSuccess(loginUser));
            if(user_data.user_type!=="2"){
				history.push('/app');
			}else{
				history.push('/administrator');
			}
        } else {
			swal(" ",loginUser.message, "error");
            console.log('login failed :', loginUser.message)
        }
    } catch (error) {
        console.log('login error : ', error)
    }
}

/*const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithReportOX({ payload }) {
	console.log(payload.user);
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            console.log('register failed :', registerUser.message)
        }
    } catch (error) {
        console.log('register error : ', error)
    }
}*/


const logoutAsync = async (history) => {
	var form_data = new FormData();
	form_data.append('user_id',localStorage.getItem('user_id'));
	return axios.post(REST_API_END_POINT+'register/logout',form_data).then((data)=>{	
		return data.data;
	}).catch(e => console.log("Addition failed , Error ", e));
    //await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({payload}) {
    const { history } = payload
    try {
        yield call(logoutAsync,history);
		let transfer_facility_id = localStorage.getItem('transfer_facility_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('subscriber_id');
        localStorage.removeItem('user_data');
        localStorage.removeItem('login_admin');
        localStorage.removeItem('bogo_code');
        localStorage.removeItem('cart');
        localStorage.removeItem('transfer_facility_id');
        localStorage.removeItem('payment_data');
        localStorage.removeItem('pos_customer_id');
        localStorage.removeItem('shop_customer_id');
        localStorage.removeItem('transfer_'+transfer_facility_id);
    } catch (error) {
    }
}



/*export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithReportOX);
}*/

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginAsSubscriber() {
    yield takeEvery(LOGIN_AS_SUBSCRIBER, loginWithUserId);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
		fork(watchLoginAsSubscriber),
        fork(watchLogoutUser),
        //fork(watchRegisterUser)
    ]);
}