import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvInput, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';

import { zeroPad, getCurrencySymbol } from "../../../helpers/Utils";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, CUSTOMER_ORDER_PAYMENTS, DEFAULT_QTY_TYPES, LBS_CONSTANT } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ReceiveOrderModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null,
			facility_data: null,
			order_products: [],
			isClicked:false,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
	
    }
	
	componentDidMount() {		
		var facility_data = localStorage.getItem('facility_data');
		facility_data =  dataCrypto.decrypt(facility_data);
		
		this.setState({
			facility_data
		});
	}
	
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				axios.post(REST_API_END_POINT+'receive-inventory/get-order-detail',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_products,
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
	
	onChange = e => {
		switch (e.target.name) {
		  case 'transport_manifest':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("receive_order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("approveForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);

			axios.post(REST_API_END_POINT+'receive-inventory/receive-order',formData).then((res)=>{
				var data = res.data;
				
				// document.getElementById("receive_order").disabled=false;
				this.props.dataListRender();
				this.props.toggleModal();
				
				if(data.status==1){
					
					swal("Success",data.msg,"success");
				}else{
					swal("Failed",data.msg,"warning");
				}
					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				// document.getElementById("receive_order").disabled=false;
			});

		}
	}
	
    render() {
		const { order_data, order_products, facility_data } = this.state;
		const current_currency_symbol=getCurrencySymbol();
		
		let is_igst=true;
		let is_utgst=false;
		/*if(facility_data!=null && order_data!=null){
			is_igst = order_data.vendor_state !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}
		}*/
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Receive Order
				</ModalHeader>
				
				<AvForm
					id="approveForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{order_data!=null ? <Fragment>
							<Row>
								<Colxx md="12">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{zeroPad(order_data.order_number,6)}</div>
										<div className="border-bottom pt-2 mb-3" />
										<Table responsive bordered className="special-table-layout">
											<thead>
												<tr>
													<th className="mb-2">Product</th>						
													<th className="mb-2 text-right">Unit Cost</th>						
													<th className="mb-2 text-right">Quantity</th>
													<th className="mb-2 text-right">Total</th>						
													<th className="mb-2">Purchase Invoice Number</th>						
													{/*<th className="mb-2">On Consignment</th>						
													{/*<th className="mb-2">Passed/Failed</th>*/}					
												</tr>
											</thead>
											<tbody>
												{order_products.map((row,index)=>{
													let qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==row.qty_type });
													let qty = parseFloat(row.qty)
													if(qty_type.value=="2"){
														qty/=LBS_CONSTANT;
													}
													return(<Fragment key={index}><tr >
													<td>{row.product} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
													<td className="text-right">{current_currency_symbol}{number_format(parseFloat(row.unit_price),2,'.','')}</td>
													<td className="text-right">{number_format(parseFloat(qty),2,'.','')}{" "}{qty_type.label}</td>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.total_amount),2,'.','')}</td>
													<td>{row.notes}</td>
													{/*<td>{row.on_consignment==1 ? "Yes":"No"}</td>
													{/*<td>{row.product_type==1 ? (row.test_passed==1 ? "Passed":"Failed"):""}</td>*/}
												</tr>
												{row.order_batches.length ? <tr>
													<td colSpan="6">
														<Table bordered>
															<thead>
																<tr>
																	<th className="mb-2">Batch #</th>						
																	{row.product_type==1 ? <th className="mb-2">Harvest Date</th>:null}					
																	<th className="mb-2">Package Date</th>
																	<th className="mb-2">Exp Date</th>
																	<th className="mb-2">Stock ID</th>
																	{/*row.qty_type!=3 && row.content_type==0 ? <Fragment>
																		<th className="mb-2 text-right">Gross Weight</th>
																		<th className="mb-2 text-right">Net Weight</th>
																		<th className="mb-2 text-right">Label Weight</th>
																	</Fragment>:*/}
																	<Fragment>
																		<th className="mb-2 text-right">Quantity</th>
																		<th className="mb-2 text-right">Received Quantity</th>
																	</Fragment>
																	{row.product_type==1 ? <th className="mb-2 text-right">Amount of Flower</th>:null}
																</tr>
															</thead>
															<tbody>
															{row.order_batches.map((r,i)=>{
																let qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==row.qty_type });
																if(row.content_type!=0){
																	qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==3 });
																}
																return(<tr key={i}>
																	<td>{r.batch_number}</td>
																	{row.product_type==1 ? <td>{r.harvest_date!=0 ? moment(r.harvest_date*1000).format('MM/DD/YYYY'):""}</td>:null}
																	<td>{r.package_date!=0 ? moment(r.package_date*1000).format('MM/DD/YYYY'):""}</td>
																	<td>{r.exp_date!=0 ? moment(r.exp_date*1000).format('MM/DD/YYYY'):""}</td>
																	<td>{r.package_uid}</td>
																	{/*row.qty_type!=3 && row.content_type==0 ? <Fragment>
																	<td className="text-right">{number_format(parseFloat(r.gross_pkg),2,'.','')}{" "}{qty_type.label}</td>
																	<td className="text-right">{number_format(parseFloat(r.net_mmj),2,'.','')}{" "}{qty_type.label}</td>
																	<td className="text-right">{number_format(parseFloat(r.rcvd_total),2,'.','')}{" "}{qty_type.label}</td>
																	</Fragment>:*/}
																	<Fragment>
																		<td className="text-right">{number_format(parseFloat(r.gross_pkg),2,'.','')}{" "}{qty_type.label}</td>
																		<td className="text-right">{number_format(parseFloat(r.rcvd_total),2,'.','')}{" "}{qty_type.label}</td>
																	</Fragment>
																	{row.product_type==1 ? <td className="text-center">{r.rcvd_amt_of_flower}</td>:null}
																</tr>)
															})}
															</tbody>
														</Table>
													</td>
												</tr>:""}
												</Fragment>)
												})}
											</tbody>
										</Table>
									</div>
								</Colxx>
							</Row>
							
						</Fragment>:""}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="receive_order">
							<IntlMessages id="customer.submit" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
