import React from "react";
import DatePicker from "react-datepicker";
import range from "lodash/range";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import moment from 'moment';

const years = range(1900, getYear(new Date()) + 10, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default class Default extends React.Component {
  constructor(props) {
    super(props);
	
    this.state = {
		commonDate: null
    };
  }

	/*componentDidUpdate(prevProps,prevState) {
		var commonDate = null

		if(this.props.selected!=null && prevProps.selected!=this.state.commonDate)
    {
			console.log("Value Found",this.props.selected);
			commonDate=this.props.selected;
			this.setState({ commonDate : commonDate });
		}
		else
    {
      console.log("Property Selected: ",this.props.selected);
      console.log("Previous Property Selection: ",prevProps.selected);
      console.log("Current State Value: ",this.state.commonDate);
			console.log("Null Found");
		}
	}*/

  handleChange = date => {
    this.setState({
      commonDate: date
    });
  };

  render() {
    return (

          <DatePicker
            id={this.props.id}
            name={this.props.name}
            className={this.props.className}
            placeholderText={this.props.placeholderText}
			autoComplete="off"
            dateFormat={this.props.dateFormat}
			ref={el => { if (el && el.input) { el.input.readOnly = true; } }}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <button
                    type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={getYear(new Date(date.format()))}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(new Date(date.format()))]}
                  onChange={({ target: { value } }) => changeMonth(value)}
                >
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            selected={this.props.selected}
            minDate={(this.props.minDate)?this.props.minDate:null}
            maxDate={(this.props.maxDate)?this.props.maxDate:null}
            onChange={(commonDate)=>{  if(typeof(this.props.onChange)=='function'){
              this.props.onChange(commonDate)
            }else{
              this.handleChange(commonDate);
            }}}
          />

    );
  }
}
