import React, { Component }  from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import Barcode from 'react-barcode';
import moment from "moment";

import {LBS_CONSTANT, LITER_CONSTANT, INVENTORY_SOURCES} from "../../../../constants/defaultValues";

export default class PrintPackageBarcodes extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { product_packages, product, selectedIds, facility, finishedProduct  } = this.props;
		// const qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==product.qty_type });
		
		var packages = JSON.parse(JSON.stringify(product_packages));
		//packages = packages.filter(r=>{ return selectedIds.indexOf(r.package_id)!=-1 });
		
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 38mm 25mm;
						  margin:0;
						}
					}
				`}} />
				{packages.map((row,index)=>{
					return (
						<div style={{padding:'2px 10px 2px 3px'}} key={index} >                  
							<div style={{width:'38mm', height:'25mm', fontFamily: 'sans-serif'}}>
								{/* Lic#: {facility.license_number} <br/>
								Date: {moment(row.timestamp*1000).format('YYYY-MM-DD')} <br/>
								{finishedProduct ? <div>Quantity: {row['quantity']}</div>:<div>Total Weight: {(row['qty_type']==1) ? row['quantity']+' GR': (row['qty_type']==2 ? Math.round(row['quantity']/LBS_CONSTANT,2)+' KG':(row['qty_type']==4 ? Math.round(row['quantity']/LITER_CONSTANT,2)+' Liters':row['quantity']+' Unit'))}</div>}
								Batch # {row.barcode} <br/> */}
								
								<div className="text-center">
								{row.name} <br/>
									<Barcode value={row.barcode} format={"EAN13"} height={25} width={1.8} margin={0} marginTop={5} displayValue={true} />
									{/* <Barcode value={row.barcode}  height={25} width={0.95} margin={0} marginTop={5} displayValue={false} />
									<p className="text-center">{(row.barcode.match(/.{1,4}/g)).join(' ')}</p> */}
								</div>
							</div>
							{index!=(packages.length-1) ? <div className="page-break-style"></div>:""}
						</div>
					)
				})}
			</div>
		);
	}
};