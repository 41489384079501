import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,Input,
  Card, CardHeader, Table, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';

import { zeroPad } from "../../helpers/Utils";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CompleteOrderModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			currency_code: 'USD',
			order_data: null,
			drawer_id: null,
			order_products: [],
			currencies: [],
			cash_total:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			},
			config:[],
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
	
    }
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				var user_id = localStorage.getItem('user_id');
				formData.append('order_id',this.props.order_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'point-of-sale/data-for-refund-order',formData).then((res)=>{
					if(res.data.status==1){
						var data = res.data.data;
						let order_products = data.products;
						order_products = order_products.map(r=>{
							r.return_product_qty_value="0";
							return r;
						})
						this.setState({ 
							order_data:data.invoice,
							drawer_id:data.drawer_id,
							currency_code:data.currency_code,
							currencies:data.currencies,
							order_products,
							cash_total:{
								'1_s':0,
								'2_s':0,
								'5_s':0,
								'10_s':0,
								'20_s':0,
								'50_s':0,
								'100_s':0,
								'cents':0,
								'nickles':0,
								'dimes':0,
								'quarters':0,
								'50_cents':0,
								'1_d':0
							},
						});
					}else{
						this.props.toggleModal();
						swal("Warning!",res.data.msg,"warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("complete-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			let formData = new FormData(document.getElementById("refundForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
            formData.append('drawer_id',this.state.drawer_id);
			formData.append('currency_code',this.state.currency_code);
			
			let api_endpoint ="drawer/check-drawer-stock";
			
			axios.post(REST_API_END_POINT+api_endpoint,formData).then((res)=>{
				var data = res.data;
				
				if(data.proceed){				
					let formData = new FormData(document.getElementById("refundForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);
					formData.append('order_id',this.props.order_id);
					formData.append('currency_code',this.state.currency_code);
					
					
                   if(formData.return_amount){
					swal("Failed","failed","warning");
					return;
				   }
					axios.post(REST_API_END_POINT+'point-of-sale/refund-order',formData).then((res)=>{
						var data = res.data;
						
						document.getElementById("complete-order").disabled=false;
						this.props.dataListRender();
						this.props.toggleModal();
						
						if(data.status==1){
							swal("Success",data.msg,"success");
						}else{
							swal("Failed",data.msg,"warning");
							return;
						}
						this.setState({
							config:[]
						})
							
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("complete-order").disabled=false;
					});
				}else{
					swal("There is no enough money in the drawer");
					document.getElementById("complete-order").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("complete-order").disabled=false;
			});
		}
	}
	
	returnAmount(total_prize,id){
	
		let config=this.state.config;
		
		let Id= config.find(obj => obj.id ===id);
		
		
		if(config.find(obj => obj.id ===id)){
		config.map(r=>{
			
		
			if(r.id==id){
			r.amount=total_prize
			
			}
		  
		
			return r;
			})
		}
		else{
		config.push({ id:id,amount:total_prize })
		}
	
	
	
	this.setState({config})
	}
    render() {
		const { order_data,order_products } = this.state;

		var cash_total = this.state.cash_total;	
		var total=0;
	// 	{ this.state.config.map(r=>{
			
	// 			total=total+r.amount
				
			
			
	// 	})
	// }
		if(this.state.cash_total!=null){
			total = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
		}
		
		const currencies = this.state.currencies;
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.state.currency_code });
		var field_view = [];
		if(typeof selected_currency!='undefined'){
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				
				field_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<AvGroup className="denomination-qty">
											<InputGroup>
												<InputGroupAddon addonType="prepend" onClick={e=>{
													var cash_total = this.state.cash_total;
													cash_total[key]+=1;
													this.setState({
														cash_total
													});
												}} >+</InputGroupAddon>
												<AvField
													id={key}
													name={key}
													type="number"
													placeholder={currency_struct[key].alt}
													min="0"
													step="1"
													value={this.state.cash_total[key]!=0?this.state.cash_total[key]:''}
													onChange={(e)=>{
														var cash_total = this.state.cash_total;
														if(e.target.value!=''){
															cash_total[key]=e.target.value;
														}else{
															cash_total[key]=0;
														}
														this.setState({
															cash_total
														});
													}}
												/>
												<InputGroupAddon addonType="append" onClick={e=>{
													var cash_total = this.state.cash_total;
													if(cash_total[key]!=0){
														cash_total[key]-=1;
														this.setState({
															cash_total
														});
													}
												}} >-</InputGroupAddon>
											</InputGroup>
										</AvGroup>
									</Colxx>
								</Fragment>)
				
			}
		}
	const total_amount=0;	
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Order Refund
				</ModalHeader>
				
				<AvForm
					id="refundForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{order_data!=null ? <Fragment>
							<Row>
								<Colxx md="12">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{zeroPad(order_data.order_number,6)}</div>
										<div className="border-bottom pt-2 mb-3" />
										<Table responsive bordered>
											<thead>
												<tr>
													<th className="mb-2">Product</th>						
													<th className="mb-2 text-right">Quantity</th>						
													<th className="mb-2 text-right">Returned Quantity</th>						
													<th className="mb-2 text-right">Return Quantity</th>	
													<th className="mb-2 text-right">Total Amount</th>						
													<th className="mb-2 text-right">Comment</th>						
												</tr>
											</thead>
											<tbody>
												
												{order_products.map((row,index)=>{
													var quantity = parseFloat((parseFloat(row['product_qty'])-parseFloat(row['return_product_qty'])).toFixed(2));
												
													return(<tr key={index}>
													<td>{row.product_name} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
													<td className="text-right">
														{number_format(parseFloat(row.product_qty),2,'.','')}
														<Input type="hidden" name={"product_qty["+index+"]"} value={quantity}/>
														<Input type="hidden" name={"deal_ids["+index+"]"} value={row.deal_id}/>
														<Input type="hidden" name={"deal_products["+index+"]"} value={row.deal_products!=null ? 1:0}/>
														<Input type="hidden" name={"id["+index+"]"} value={row.id}/>
														<Input type="hidden" name={"unique_code["+index+"]"} value={row.product_uniq_code}/>
													</td>
													<td className="text-right">
														{number_format(parseFloat(row.return_product_qty),2,'.','')}
													</td>
													<td>
														<AvField 
															className={quantity<=0 ? 'd-none':''} 
															type="number" 
															name={"return_product_qty["+index+"]"}
															value={quantity<=0? '0':row.return_product_qty_value}
															onChange={(e)=>{
																let order_products = this.state.order_products;
																order_products = order_products.map(r=>{
																	if(r.id==row.id){
																		r.return_product_qty_value=e.target.value;
																		r.total_prize=r.return_product_qty_value*row['product_price']
																	   this.returnAmount(r.total_prize,row.id)
																	   
																	}
																	
																	return r;
																})
																
																
															}}
															validate={{
															  required: {
																value: true,
																errorMessage: "This field is required."
															  },
															  min: {
																value: "0",
																errorMessage:"The value must be greater than or equal to 0"
															  },
															  max: {
																value: quantity.toString(),
																errorMessage:"The value must be less than or equal to "+quantity.toString()
															  },
															}}
														/>
													</td>
													
													<td className="text-right">
													{ this.state.config.map(r=>{
																	if(r.id==row.id){
																		total=total+r.amount
																		return r.amount;
																	   
																	}
																	
																	
																})
															}
													</td>
													<td>
														<AvField 
															className={quantity<=0 ? 'd-none':''} 
															type="text" 
															name={"return_product_comment["+index+"]"}
															validate={{
															  required: {
																value: row.return_product_qty_value!="0",
																errorMessage: "This field is required."
															  }
															}}															
														/>
													</td>
												</tr>)
												})}
											</tbody>
										</Table>
									</div>
								</Colxx>
							</Row>
						
							<Row className="align-items-center">
								{field_view}
							</Row>
							
							<Row className="mt-4">
								<Colxx xxs="3">
									<h3>Total</h3>
								</Colxx>
								<Colxx xxs="6">
									<AvGroup className="error-t-negative">
									  <AvField
										id="return_amount"
										name="return_amount"
										type="text"
										readOnly={true}
										placeholder="0"
										value={total}
										validate={{
										  min: {
											value: "0.01",
											errorMessage:"The value must be greater than or equal to 0.01"
										  },
										  max: {
											value: order_data.total.toString(),
											errorMessage:"The value must be less than or equal to "+order_data.total.toString()
										  },
										}}
									  />
									</AvGroup>
								</Colxx>
							</Row>
						</Fragment>:""}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="complete-order">
							Submit
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
