import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
   DropdownMenu, DropdownItem,
	CustomInput, Badge,
    } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import AddProductLabelModal from "../../../containers/inventory/AddProductLabelModal";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import PrintPackageBarcodes from "../../../components/inventory/EditProduct/Packages/PrintPackageBarcodes"
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Inventory extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.updateShopStatus = this.updateShopStatus.bind(this);
		this.printBarcodes = this.printBarcodes.bind(this);
		
		this.state = {		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			search: '',
			products: [],
			selectAll:0,
			selectedIds:[],
			user_privileges:[],
			barcodePrint:false,
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,product_id=0) => {
		this.setState({
			product_id: parseInt(product_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.products.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
     
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one product","warning");
			return;
		}
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="product";
		
		if(selectedIds.length>1){
			msg_word="products";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-inventory").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('inventory_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-products',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-inventory").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-inventory").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,product_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(product_id);
			
		}else{
		  var x=selectedIds.indexOf(product_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.products.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/get-all-products',formData).then((res)=>{
			var data = res.data;
		
			if(data.products){
				this.setState({
					products: data.products,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					products: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	updateShopStatus(product_id,status) {
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);
        formData.append('product_id',product_id);
        formData.append('shop_status',status=='Yes' ? 0:1);
		
		axios.post(REST_API_END_POINT+'inventory/update-product-shop-status',formData).then((res)=>{
			var data = res.data;
			if(data.status==1){
				var products = this.state.products;
				products = products.map(r=>{
					if(r.id==product_id){
						r.shop_status=status=='Yes' ? 0:1;
						r.in_shop=status=='Yes' ? 'No':'Yes';
					}
					return r;
				})
				
				this.setState({
					products
				});
			}
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	// printBarcode(e,value){
	// 	console.log('$$$$$$$$$$$$$$$$$$$',value);
	// 	 this.setState({barcodePrint:true});
		
	// }
	printBarcodes = (e,package_ids=null) => {
		var selectedIds = package_ids!=null ? package_ids:this.state.selectedIds;        
		if(selectedIds.length==0){
			swal("Warning","Please select at least one  Product","warning");
			return;
		}else{
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			// formData.append('product_ids',selectedIds.join(','));
			formData.append('product_ids',selectedIds);
			axios.post(REST_API_END_POINT+'inventory/get-print-barcode',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var print_packages = data.packages;
					print_packages = print_packages.map(row=>{
						row.quantity=10;
						return row;
					})
					
								
					this.setState({ print_packages });
					// var source =  document.getElementById('print-finished-barcodes-hidden').value
					// alert(source);
					// console.log('************',document.getElementsById('print112233').value);
					
					// setTimeout(
					// 	function(){ 
							//  document.getElementById('print112233').click()

							// let dd = document.getElementsById('print112233')
							// .click() 
							// console.log(dd,"123**********");
							
						
						// }
						// , 3000)

						setTimeout(function(){ document.getElementById('print-finished-barcodes-hidden').click() }, 1000)
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
  
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	
	render() {
		let data = this.state.products
		if (this.state.search) {
			data = data.filter(row => {
				if(row.strain_name==null){
					row.strain_name="";
				}
				if(row.sub_category==null){
					row.sub_category="";
				}
				if(row.unique_id==null){
					row.unique_id="";
				}
			
				return row.name.toLowerCase().includes(this.state.search) || row.barcode.toLowerCase().includes(this.state.search) || row.strain_name.toLowerCase().includes(this.state.search) || row.category.toLowerCase().includes(this.state.search) || row.sub_category.toLowerCase().includes(this.state.search) || row.source_name.toLowerCase().includes(this.state.search)||row.unique_id.toLowerCase().includes(this.state.search)
			})
		}
		
		let dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:140,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				width:245,
				Cell: props => this.state.user_privileges.indexOf(50)!==-1 ? <p className="list-item-heading">
					{/* <NavLink to={"/app/inventory/edit-product/"+props.original.product_uuid} className="btn-link" > */}
						{props.value}
						{/* </NavLink> */}
					</p>:<p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "name",
				width:245,
				Cell: props => this.state.user_privileges.indexOf(50)!==-1 ? <p className="list-item-heading">
					{/* <NavLink to={"/app/inventory/edit-product/"+props.original.product_uuid} className="btn-link" > */}
						<img src={BASE_URL+"products/"+props.original.thumbnail} className="inventory-list-thumb" /> <span>{props.value+(props.original.hsn_code!="" && props.original.hsn_code!=null ? " ("+props.original.hsn_code+")":"")}</span>
						{/* </NavLink> */}
					</p>:<p className="list-item-heading"><img src={BASE_URL+"products/"+props.original.thumbnail} className="inventory-list-thumb" /> <span>{props.value+(props.original.hsn_code!="" && props.original.hsn_code!=null ? " ("+props.original.hsn_code+")":"")}</span></p>
			},
			/*{
				Header: "Strain",
				accessor: "strain_name",
				width:100,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Product Type",
				accessor: "product_type",
				width:95,
				Cell: props => <p className="list-item-heading">{parseInt(props.value)===1 ? 'Cannabis':'Non-Cannabis'}</p>
			},*/
            
			 {
				Header: "Barcode Type",
				accessor: "manual_barcode",
				width:145,
				Cell: props => <p className="list-item-heading">{parseInt(props.value)===1 ? 'Manual':'Auto generate'}</p>
			},

			 {
				Header: "Print Barcode",
				accessor: "id",
				width:110,
				Cell: props => <Button color="primary" size="sm" className="cursor-pointer" onClick={(e)=>{ this.printBarcodes(e,props.value) }}  >
				<span className="d-inline-block d-none" >Print Barcode</span>
			</Button>
			},
				


			// {
			// 	Header: "Subcategory",
			// 	accessor: "sub_category",
			// 	width:110,
			// 	Cell: props => <p className="list-item-heading">{props.value}</p>
			// },
			// {
			// 	Header: "Available Qty",
			// 	accessor: "available_qty",
			// 	width:120,
			// 	Cell: props => <p className="list-item-heading" dangerouslySetInnerHTML={{__html:props.value}} ></p>
			// }
		];
			// if(this.state.user_privileges.indexOf(50)!==-1){
			// 	dataTableColumns.push({
			// 		Header: "In shop",
			// 		accessor: "in_shop",
			// 		width:60,
			// 		Cell: props => <p className="list-item-heading text-center">
			// 				<Switch
			// 					className="custom-switch custom-switch-primary im-inline-block inventory-list-switch"
			// 					checked={props.value=='Yes'}
			// 					onChange={status => { this.updateShopStatus(props.original.id,props.value) }}
			// 				/>					
			// 		</p>
			// 	})
			// }
			// dataTableColumns = dataTableColumns.concat([{
			// 	Header: "Source",
			// 	accessor: "source_name",
			// 	width:90,
			// 	Cell: props => <p className="list-item-heading">{props.value}</p>
			// },				
			// {
			// 	Header: "Actions",
			// 	accessor: "id",
			// 	width:60,
			// 	sortable: false,
			// 	Cell: props => this.state.user_privileges.indexOf(50)!==-1 || this.state.user_privileges.indexOf(51)!==-1 ? <Fragment>
			// 			<div className="action-btns p-0">
			// 				<ItemDropdown item={<DropdownMenu >
			// 						{this.state.user_privileges.indexOf(50)!==-1 ? <DropdownItem className="cursor-pointer p-0">
			// 							<NavLink to={"/app/inventory/edit-product/"+props.original.product_uuid} className="d-block drop-link" ><span className="d-inline-block">Edit</span></NavLink>					
			// 						</DropdownItem>:""}		
			// 						{this.state.user_privileges.indexOf(51)!==-1 ? <DropdownItem onClick={event =>this.deleteItem(event,[props.value],2)} className="cursor-pointer" >
			// 							<span className="d-inline-block" >Delete</span>
			// 						</DropdownItem>:""}
			// 					</DropdownMenu>}
			// 				/>
			// 			</div>
			// 		</Fragment>:""
			// }]);
		   
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="Print Barcode" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
		   <Row>			  
				<Colxx xxs="12">
					<div id="api_message"></div>
					<div className="float-sm-right">
						{/* {" "}{this.state.user_privileges.indexOf(49)!==-1 ? <Button
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add Product Label
						</Button>:""}
						   {" "}{this.state.user_privileges.indexOf(51)!==-1 ? <Button
							id="delete-inventory"
							color="primary"
							size="sm"
							className=""
							onClick={(e)=>{ this.deleteItem(e,this.state.selectedIds,2) }}
						  >
							<IntlMessages id="pages.delete" />
						</Button>:""} */}
						{/* <Button
					color="primary"
					size="sm"
					className=""
					onClick={this.toggleSelectAll}
				  >
					Select All
				</Button>{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.printBarcodes}
				  >					
					{this.state.selectedIds.length ? <ReactToPrint
						trigger={() => <span className="d-block cursor-pointer" id="print-package-barcodes-hidden" >Print Barcodes</span>}
						content={() => this.componentRef}
						copyStyles={true}
						pageStyle={""}
					/>:"Print Barcodes"}
				</Button> */}
				{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.toggleSelectAll}
				  >
					Select All
				</Button>{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.printBarcodes}
				  >					
					Print Barcodes
				</Button>				
					</div>
					<div className="mb-4 dropdown-edit small-padd-table">
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
					
			  </Colxx>
				{this.state.user_privileges.indexOf(49)!==-1 ? <AddProductLabelModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.dataListRender}
					user_privileges = {this.state.user_privileges}
				/>:""}
			</Row>
		</Card>

		
		{this.state.products.length>0 && this.state.print_packages!=undefined ? <div className={"d-none"}>
				<ReactToPrint
					// trigger={() => <Button className="d-block cursor-pointer print112233222" id="print112233" value="abcde">Print Barcodes</Button>}
					trigger={() => <span className="d-block cursor-pointer" id="print-finished-barcodes-hidden" >Print Barcodes</span>}
					content={() => this.componentRef}
					copyStyles={true}
					pageStyle={""}
				/>
				<PrintPackageBarcodes 
					ref={el => (this.componentRef = el)} 
					product_packages={this.state.print_packages} 
					product={this.state.products}
					facility={this.props.facility}					
					selectedIds={this.state.selectedIds} 
					finishedProduct={true}
				/>
			</div>:""}
		{/* {this.state.barcodePrint?<PrintPackageBarcodes
		/>:""} */}
      </Fragment>
    );
	
  }
}
