/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1200;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English" },
  { id: "es", name: "Español" }
];
export const JQUERY_MODULE={$ : require('jquery'), jQuery : require('jquery')};

/*Motagistics CodeIgniter REST API*/
/*export const REST_API_END_POINT = "https://api.underground710.com/webservice/";
export const REST_API_BASE_URL = "https://api.underground710.com/";
export const BASE_URL = "https://api.underground710.com/uploads/";
export const SITE_URL = "https://underground710.com/";*/

// export const REST_API_END_POINT = "https://api-alikhtayar.skyniche.website/webservice/";
// export const REST_API_BASE_URL = "https://api-alikhtayar.skyniche.website/";
// export const BASE_URL = "https://api-alikhtayar.skyniche.websitee/uploads/";
// export const SITE_URL = "https://al-ikhtayar.skyniche.website/";

// export const REST_API_END_POINT = "http://localhost/Al-ikhtayar/webservice/";
// export const REST_API_BASE_URL = "http://localhost/Al-ikhtayar/";
// export const BASE_URL = "http://localhost/Al-ikhtayar/uploads/";
// export const SITE_URL = "http://localhost/Al-ikhtayar/";

export const REST_API_END_POINT = "https://al-ikhtayar-api.skyniche.website/webservice/";
export const REST_API_BASE_URL = "https://al-ikhtayar-api.skyniche.website/";
export const BASE_URL = "https://al-ikhtayar-api.skyniche.website/uploads/";
export const SITE_URL = "https://alikhtayar.com";

/*export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",       
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563"
};*/

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = "light.purple";
export const projectTitle = "AL IKHTAYAR TRADING";
export const themeColor = "#6721a2";
export const dataEncryptKey = "b80f009797557476873d756bf45d934f";

export const GOOGLE_API_KEY = "AIzaSyCcWa6ymnouo2vlRhbVBAMyv9MyQvH8yug";

export const LBS_CONSTANT = 1000; //in this application is constant is used for kilogram
export const TON_CONSTANT = 1000000;
export const LITER_CONSTANT = 1000;
export const OUNCE_CONSTANT = 1000; //in this application is constant is used for kilogram
export const EXISE_TAX = 24;
export const AGE_WARNING = false;
export const NOT_PAY_USERS = '1,2';
export const INVENTORY_SOURCES = {'0':'Inventory', '1':'Grow House', '2':'Supplier', '3':'Processor', '4':'Processor', '5':'Processor', '6':'Packaging', '7':'Oven', '8':'MCP'};
export const DEFAULT_QTY_TYPES = [
    { label: "Grams", value: "1", key: 0 },
    { label: "Kilograms", value: "2", key: 1 },
    { label: "Units", value: "3", key: 2 },
    //{ label: "Litres", value: "4", key: 3 }
];

export const CUSTOMER_ORDER_STATUS = [
    { label: "Waiting for Approval", value: "0", badge: "warning", key: 0 },
    { label: "Approved", value: "1", badge: "info", key: 1 },
    { label: "In Process", value: "2", badge: "info", key: 2 },
    { label: "Completed", value: "3", badge: "success", key: 3 },
    { label: "Canceled", value: "4", badge: "danger", key: 4 },
];

export const CUSTOMER_ORDER_PAYMENTS = [
    { label: "Cash", value: "Cash", key: 0 },
    { label: "Cheque", value: "Cheque", key: 1 },
    { label: "Debit Card", value: "Debit Card", key: 2 },
    { label: "CONSIGNMENT", value: "CONSIGNMENT", key: 3 },
    //{ label: "COD", value: "COD", key: 4 },
    //{ label: "Express Checkout", value: "Express", key: 5 },
    //{ label: "Pick up in store", value: "Pick up in store", key: 6 },
    { label: "Credit", value: "Credit", key: 7 },
];

export const EQUIPMENT_STATUS = [
    { label: "IDLE", value: "0", color: "#d4d4d4", key: 0 },
    { label: "LOADING", value: "1", color: "#f2d505", key: 1 },
    { label: "RUNNING", value: "2", color: "#007eff", key: 2 },
    { label: "PAUSED", value: "3", color: "#ff1a1a", key: 3 },
    { label: "RUN COMPLETE", value: "4", color: "#00a80e", key: 4 },
    { label: "OUT OF SERVICE", value: "5", color: "#000", key: 5 },
];

export const PUBNUB_CREDENTIALS = {
	publishKey: "pub-c-970bdc76-66b8-48bb-9d6f-c4611cb2d2c6",
	subscribeKey: "sub-c-27dbc7bc-2b0b-11eb-9713-12bae088af96",
};

export const DEFAULT_JAR_TYPES = [
    { label: "Mason Jar", value: "1", key: 0 },
    { label: "Pyrex Dish", value: "2", key: 1 },
    { label: "Parchment Boat", value: "3", key: 2 }
];

export const IG_TOKEN = "IGQVJWYjVyRDFPNDZAINWhTdVhrM1dGZAWFqUE9nellUT21CbFN2UnFheVloWTJxenNGSFdqY29Mdjc4c3k5WFZA1WERkOVNsTmxNMGgyOHFFQm9ha3pldXNhVXc5ZAUFCOHE0dkM3TXZAvUkw0Q0NEWHVuMAZDZD";