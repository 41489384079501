import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import plantInventory from "./growhouse/plant-inventory";
import historicalPlantInventory from "./growhouse/historical-plant-inventory";
import plantAudit from "./growhouse/plant-audit";
import plantSummary from "./growhouse/plant-summary";
import plantMovement from "./growhouse/plant-movement";
import removedPlants from "./growhouse/removed-plants";
import strainCounts from "./growhouse/strain-counts";
import motherYields from "./growhouse/mother-yields";
import yields from "./growhouse/yields";
import yieldsStatistics from "./growhouse/yields-statistics";
import growCostTracking from "./growhouse/grow-cost-tracking";

import currentInventory from "./inventory/current-inventory";
import historicalInventory from "./inventory/historical-inventory";
import inventoryAdjustment from "./inventory/inventory-adjustment";
import inventoryForensics from "./inventory/inventory-forensics";
import inventoryAudit from "./inventory/inventory-audit";
import destructionEvents from "./inventory/destruction-events";
import products from "./inventory/products";
import productPricing from "./inventory/product-pricing";
import kitchen from "./inventory/kitchen";
import receiveInventory from "./inventory/receive-inventory";

import sales from "./sales/sales";
import salesByCategory from "./sales/sales-by-category";
import salesCounts from "./sales/sales-counts";
import salesCountHour from "./sales/sales-count-hour";
import salesCountDay from "./sales/sales-count-day";
import salesCountDayHour from "./sales/sales-count-day-hour";
import bestSellers from "./sales/best-sellers";
import bestSellersQuantity from "./sales/best-sellers-quantity";
import worstSellers from "./sales/worst-sellers";
import worstSellersQuantity from "./sales/worst-sellers-quantity";
import discountDetail from "./sales/discount-detail";
import discounts from "./sales/discounts";
import discountByStaff from "./sales/discount-by-staff";
import discountByProduct from "./sales/discount-by-product";
import autoApplyDiscounts from "./sales/auto-apply-discounts";
import cashClose from "./sales/cash-close";
import cashCloseDetail from "./sales/cash-close-detail";
import payments from "./sales/payments";
import payouts from "./sales/payouts";
import wholesale from "./sales/wholesale";
import wholesalePayments from "./sales/wholesale-payments";
import voidTickets from "./sales/void-tickets";
import salesTrends from "./sales/sales-trends";
import salesDemographics from "./sales/sales-demographics";
import complexSales from "./sales/complex-sales";
import consolidatedSalesReport from "./sales/consolidated-sales-report";

import VendorPendingPayments from "./payments/vendor-pending-payments";
import CustomerPendingPayments from "./payments/customer-pending-payments";
import SupplierPaymentAgainstVehicles from "./payments/vendor-payments-against-vehicle";

import productionProcess from "./production-process";
import customers from "./customers";
import thcreport from "./thcreport";
import staff from "./staff";
import waste from "./waste";
import Expenses from './sales/expense';
import profitLossReport from "./profit-loss";
import SalesByFilter from "./sales/sales-by-filter"
import SalesRefunded from "./sales/sales-by-refund"
import ProfiltLossProduct from './profit-loss -product';
const Myreports = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/plant-inventory`} />
			<Route path={`${match.url}/plant-inventory`} component={plantInventory} />
			<Route path={`${match.url}/historical-plant-inventory`} component={historicalPlantInventory} />
			<Route path={`${match.url}/plant-audit`} component={plantAudit} />
			<Route path={`${match.url}/plant-summary`} component={plantSummary} />
			<Route path={`${match.url}/plant-movement`} component={plantMovement} />
			<Route path={`${match.url}/removed-plants`} component={removedPlants} />
			<Route path={`${match.url}/strain-counts`} component={strainCounts} />
			<Route path={`${match.url}/mother-yields`} component={motherYields} />
			<Route path={`${match.url}/yields`} component={yields} />
			<Route path={`${match.url}/yields-statistics`} component={yieldsStatistics} />
			<Route path={`${match.url}/grow-cost-tracking`} component={growCostTracking} />
			<Route path={`${match.url}/profit-loss-product`} component={ProfiltLossProduct} />
			<Route path={`${match.url}/current-inventory`} component={currentInventory} />
			<Route path={`${match.url}/historical-inventory`} component={historicalInventory} />
			<Route path={`${match.url}/inventory-adjustment`} component={inventoryAdjustment} />
			<Route path={`${match.url}/inventory-forensics`} component={inventoryForensics} />
			<Route path={`${match.url}/inventory-audit`} component={inventoryAudit} />
			<Route path={`${match.url}/destruction-events`} component={destructionEvents} />
			<Route path={`${match.url}/products`} component={products} />
			<Route path={`${match.url}/product-pricing`} component={productPricing} />
			<Route path={`${match.url}/kitchen`} component={kitchen} />
			<Route path={`${match.url}/receive-inventory`} component={receiveInventory} />
			
			<Route path={`${match.url}/sales`} component={sales} />
			
			<Route path={`${match.url}/sales-by-filter`} component={SalesByFilter} />
			<Route path={`${match.url}/sales-by-refund`} component={SalesRefunded} />
			<Route path={`${match.url}/sales-by-category`} component={salesByCategory} />
			<Route path={`${match.url}/sales-counts`} component={salesCounts} />
			<Route path={`${match.url}/sales-count-hour`} component={salesCountHour} />
			<Route path={`${match.url}/sales-count-day`} component={salesCountDay} />
			<Route path={`${match.url}/sales-count-day-hour`} component={salesCountDayHour} />
			<Route path={`${match.url}/best-sellers`} component={bestSellers} />
			<Route path={`${match.url}/best-sellers-quantity`} component={bestSellersQuantity} />
			<Route path={`${match.url}/worst-sellers`} component={worstSellers} />
			<Route path={`${match.url}/worst-sellers-quantity`} component={worstSellersQuantity} />
			<Route path={`${match.url}/discount-detail`} component={discountDetail} />
			<Route path={`${match.url}/discounts`} component={discounts} />
			<Route path={`${match.url}/discount-by-staff`} component={discountByStaff} />
			<Route path={`${match.url}/discount-by-product`} component={discountByProduct} />
			<Route path={`${match.url}/auto-apply-discounts`} component={autoApplyDiscounts} />
			<Route path={`${match.url}/cash-close`} component={cashClose} />
			<Route path={`${match.url}/cash-close-detail/:detail_id`} component={cashCloseDetail} />
			<Route path={`${match.url}/payments`} component={payments} />
			<Route path={`${match.url}/payouts`} component={payouts} />
			<Route path={`${match.url}/wholesale-report`} component={wholesale} />
			<Route path={`${match.url}/wholesale-payments`} component={wholesalePayments} />
			<Route path={`${match.url}/void-tickets`} component={voidTickets} />
			<Route path={`${match.url}/sales-trends`} component={salesTrends} />
			<Route path={`${match.url}/sales-demographics`} component={salesDemographics} />
			<Route path={`${match.url}/complex-sales`} component={complexSales} />
			<Route path={`${match.url}/consolidated-sales-report`} component={consolidatedSalesReport} />
			
			<Route path={`${match.url}/vendor-pending-payments`} component={VendorPendingPayments} />
			<Route path={`${match.url}/customer-pending-payments`} component={CustomerPendingPayments} />
			<Route path={`${match.url}/vendor-payments-against-vehicle`} component={SupplierPaymentAgainstVehicles} />
			
			<Route path={`${match.url}/production-process`} component={productionProcess} />
			<Route path={`${match.url}/customers`} component={customers} />
			<Route path={`${match.url}/thc-report`} component={thcreport} />
			<Route path={`${match.url}/staffs`} component={staff} />
			<Route path={`${match.url}/waste`} component={waste} />
			<Route path={`${match.url}/expenses`} component={Expenses} />
			<Route path={`${match.url}/profit-loss`} component={ProfiltLossProduct} />
			<Redirect to="/error" />
        </Switch>
    </div>
);
export default Myreports  ;