import React from "react";
import { Card, CustomInput, Badge,Button, DropdownItem, DropdownMenu, } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ItemDropdown from "../../../components/customer/ItemDropdown";

const DataListView = ({ result, isSelect, collect, onCheckItem,editmodal, editmodalother, deleteItem, user_privileges, register_type }) => {
var customerStatus=result.status!=1?1:0;
  return (
    <Colxx xxs="12" className="mb-3">
        <Card className="d-flex flex-row">
          <div className="pl-2 d-flex flex-grow-1 min-width-zero">
            <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center p-2">             
                <p className="list-item-heading mb-1 w-12 w-sm-100 d-none d-lg-inline">
                  {result.barcode}
                </p>              
			   <NavLink to={`?p=${result.vendor_id}`} className="w-12 w-sm-100 d-none d-lg-inline">
                <p className="list-item-heading mb-1">
                  {result.vendor_name}
                </p>
              </NavLink>			  			  
              <p className="mb-1 text-muted text-small w-12 w-sm-100 d-none">
                <span className="d-none d-sm-inline d-md-none ">Trade Name: </span>{result.company_name}
              </p>
			  <p className="mb-1 text-muted text-small w-12 w-sm-100 d-none">
                <span className="d-none d-sm-inline d-md-none ">Address: </span>{result.vendor_address}
              </p>
              <p className="mb-1 text-muted text-small w-12 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Phone: </span>{result.phone}
              </p>
              <p className="mb-1 text-muted text-small w-12 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Email: </span>{result.email_id}
              </p>
			  {register_type=="0" ? <div className="mb-1 w-9 w-sm-100">
                <Badge color={parseInt(result.register_type)==2 ? "info":"secondary"} pill>
                  {parseInt(result.register_type)==2 ? "Individual":"Company"}
                </Badge>
              </div>:""}
              <div className="mb-1 w-9 w-sm-100">
                <Badge color={result.status==1?"success":"danger"} pill>
                  {result.status==1?"Active":"Inactive"}
                </Badge>
              </div>
              {user_privileges.indexOf(83)!==-1 || user_privileges.indexOf(84)!==-1 ? <div className="action-btns w-9 w-sm-100">
				  <ItemDropdown item={<DropdownMenu >
					{user_privileges.indexOf(83)!==-1 ? <DropdownItem onClick={event =>{ parseInt(result.register_type)==1 ? editmodal(event,result.vendor_id):editmodalother(event,result.vendor_id) }}>
						<i className="simple-icon-pencil" />{" "}<span className="d-inline-block">Edit</span>
					</DropdownItem>:""}
					{user_privileges.indexOf(84)!==-1 ?<DropdownItem onClick={event =>deleteItem(event,result.vendor_id,customerStatus,result.register_type)}>
							<i className={result.status!=1?'simple-icon-check':'simple-icon-ban'} />{" "}<span className="d-inline-block">{result.status!=1?"Activate":"Deactivate"}</span>
					</DropdownItem>:""}
					{user_privileges.indexOf(84)!==-1 ?<DropdownItem onClick={event =>deleteItem(event,result.vendor_id,2,result.register_type)} >
						<i className="simple-icon-trash" />{" "}<span className="d-inline-block">Delete</span>
					</DropdownItem>:""}
				  </DropdownMenu>} />
              </div>:""}
            </div>
            {user_privileges.indexOf(84)!==-1 ? <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
              <CustomInput
                className="item-check mb-0"
                type="checkbox"
                id={`check_${result.vendor_id}`}
                checked={isSelect}
                onChange={event => onCheckItem(event, result.vendor_id,result.status)}
				  className={classnames("d-flex flex-row", {
					active: isSelect
				  })}
                label=""
              />
            </div>:""}
          </div>
        </Card>
    </Colxx>
  );
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(DataListView);
