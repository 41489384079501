import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
// import { getCookie, setCookie, getCurrencySymbol, getCustomerWord } from "../../../../helpers/Utils";
import { getCookie, setCookie , getCustomerWord } from "../../../helpers/Utils";
import swal from 'sweetalert';
import moment from 'moment';
import { uuid } from "uuidv4";
import { zeroPad, getCurrencySymbol } from "../../../helpers/Utils";
import {REST_API_END_POINT,DEFAULT_QTY_TYPES,LBS_CONSTANT,LITER_CONSTANT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, CUSTOMER_ORDER_PAYMENTS } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class PaymentModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null,
			facility_data: null,
			order_payment_splits: [],
			setShow:true,
			due_amount:"",
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addPaymentRow = this.addPaymentRow.bind(this); 
		this.removePaymentRow = this.removePaymentRow.bind(this); 
		
	
    }
	componentDidMount(){
		let order_payment_splits = this.state.order_payment_splits;
		if(order_payment_splits.length==0){
			order_payment_splits.push({
				id:uuid(),
				pay_id:"0",
				amount:"0",
				payment_mode:"Cash",
				created_at:moment().unix(),
			})
		}

	}
			
	componentDidUpdate(prevProps, prevState, snapshot){

        if(this.props.modalOpen){	
			
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
			
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
			
				formData.append('order_payment_splits',JSON.stringify(this.state.order_payment_splits));
				axios.post(REST_API_END_POINT+'receive-inventory/get-order-detail-for-payment',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						// if(res.data.order_payment_splits.length == 0){
						if(res.data.order_payment_splits.length == 0){
							
							this.setState({ 
								order_data:data.order_data,
								facility_data:data.facility_data,
								// order_payment_splits:data.order_payment_splits
							});
						}else{

							this.setState({ 
								order_data:data.order_data,
								facility_data:data.facility_data,
								order_payment_splits:data.order_payment_splits
							});
						}

						
						
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
						
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			// document.getElementById("make-payment").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			var total_amount = parseFloat(parseFloat(this.state.order_data['grand_total']).toFixed(2));

			facility_id =  dataCrypto.decrypt(facility_id);
			
			var total_due = this.state.due_amount
			var formData = new FormData(document.getElementById("makePaymentForm"));
			formData.append('total_due',total_due);
			formData.append('total_amount',total_amount)
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);
			formData.append('order_payment_splits',JSON.stringify(this.state.order_payment_splits));
            const filtered_amount= this.state.order_payment_splits.filter(r=>{
				
				return(r.payment_mode!="Credit"?r.amount==0?true:"":"")});
			console.log('$@$#R Vfn',filtered_amount);
			if(filtered_amount.length==0){

			
			axios.post(REST_API_END_POINT+'receive-inventory/make-payment',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("make-payment").disabled=false;
				this.props.dataListRender();
				this.props.toggleModal();
				
				if(data.status==1){
					this.setState({order_payment_splits: []})
					if(this.state.order_payment_splits.length==0){
						this.state.order_payment_splits.push({
							id:uuid(),
							pay_id:"0",
							amount:"0",
							payment_mode:"Cash",
							created_at:moment().unix(),
						})
						
					}
					// swal("Success",data.msg,"success").then(()=>window.location="/app/wholesale/all-orders");

				}else{
					swal("Failed",data.msg,"warning").then(()=>window.location="/app/wholesale/all-orders");
					
				}
					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("make-payment").disabled=false;
			});
		}
		else{
			swal("Warning!","Please Enter an amount greater than 0","warning");
		}
		}
	}

	 

	addPaymentRow = () =>{
		let order_payment_splits = this.state.order_payment_splits;
		order_payment_splits.push({
			id:uuid(),
			pay_id:"0",
			amount:"0",
			payment_mode:"Cash",
			created_at:moment().unix(),
		});
		this.setState({ order_payment_splits });
	}
	
	removePaymentRow = (id) =>{
		let order_payment_splits = this.state.order_payment_splits.filter(row=>{ return row.id!=id });
		this.setState({ order_payment_splits });
	}
	
	
	

	
    render() {
		const { order_data, facility_data,order_payment_splits,cart_totals,due_amount } = this.state;
		// const { order_data, facility_data, } = this.state;
		let remaining_payment;
		if(order_data){
		

			 remaining_payment = parseFloat(parseFloat(order_data['grand_total']).toFixed(2));
		

		}

		var current_currency_symbol = getCurrencySymbol();
		var sales_tax=0;
		var sgst_tax=0;
		var cgst_tax=0;
		var sub_total=0;
		var p_discount=0;
		let is_utgst = false;
		let is_igst = true;
		
		/*if(order_data!=null){
			is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}
		}*/
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Make Payment
				</ModalHeader>
				
				<AvForm
					id="makePaymentForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{
							console.log(order_data,"order_data***")
						}
						{order_data!=null ? <Fragment>
							<Row>
								<Colxx md="12">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{moment(order_data.timestamp*1000).format('YYYY')}/{zeroPad(order_data.order_number,6)}</div>
										<div className="border-bottom pt-2 mb-3" />
										<Table responsive bordered>
											<thead>
												<tr>
													<th className="mb-2">#</th>						
													<th className="mb-2">Product</th>						
													<th className="mb-2 text-right">Unit Price</th>
													<th className="mb-2 text-right">Quantity</th>
													
													{/* <th className="mb-2 text-right">Price</th>
													{is_igst ? <th className="mb-2 text-right">VAT ({order_data.tax_percentage}%)</th>:<Fragment>
														<th className="mb-2 text-right">{is_utgst ? "UTGST":"SGST"}</th>
														<th className="mb-2 text-right">CGST</th>
													</Fragment>} */}
													{/*<th className="mb-2 text-right">Discount</th>*/}
													<th className="mb-2 text-right">Total</th>		
													<th className="mb-2 ">Purchase Invoice Number</th>	
												</tr>
											</thead>
											<tbody>
												{order_data?.order_products.map((row,index)=>{
													sales_tax+=parseFloat(row.product_sales_tax);
													sgst_tax+=parseFloat(row.product_sgst_tax);
													cgst_tax+=parseFloat(row.product_cgst_tax);
													p_discount+=parseFloat(row.product_discount)+parseFloat(row.coupon_discount);
													
													let line_total=parseFloat(row.product_unit_price)*parseFloat(row.product_qty)+(parseFloat(row.product_excise_tax)+parseFloat(row.product_sales_tax)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount))
													let qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==row.qty_type });
													let p_qty = Math.round(parseFloat(row.qty));
													if(row.qty_type==1){
														p_qty = parseFloat(row.qty);
													}else if(row.qty_type==2){
														p_qty = (parseFloat(row.qty)/LBS_CONSTANT).toFixed(2);
													}else if(row.qty_type==4){
														p_qty = (parseFloat(row.qty)/LITER_CONSTANT).toFixed(2);
													}
													if(row.variation_id==order_data.discount_variation_id){
														line_total-=parseFloat(order_data.manual_discount);
													}
													sub_total+=parseFloat(row.product_unit_price)*parseFloat(row.product_qty);
													return(<tr key={index}>
													<td>{index+1}</td>
													{/* <td>{row.product} {row.product!=null ? "("+row.product+")":""}</td> */}
													<td>{row.product}</td>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.unit_price),2,'.','')}</td>
													<td className="text-right">{number_format(parseFloat(p_qty),2,'.','')}{" "}{qty_type.label}</td>
													{/* <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat((parseFloat(row.product_unit_price)*parseFloat(row.product_qty)).toFixed(2)),2,'.','')}</td> */}
													{/* {is_igst ? <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sales_tax),2,'.','')}</td>:<Fragment>
														<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sgst_tax),2,'.','')}</td>
														<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_cgst_tax),2,'.','')}</td>
													</Fragment>} */}
													{/*<td className="text-right">{current_currency_symbol}{""}{row.variation_id!=order_data.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_data.manual_discount),2,'.','')}</td>*/}
													<td className="text-right">{current_currency_symbol}{""+number_format(row.total_amount,2,'.','')}</td>
												    <td className="text-right">{row.notes}</td>
												</tr>)
												})}
											</tbody>
										</Table>
										<div className="d-flex flex-column">
										<div className="border-bottom pt-3 mb-2" />
										<Table borderless className="d-flex justify-content-end">
										{order_data!=null?<tbody>
												<tr>
													<td className="pb-0">Subtotal :</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(order_data.sub_total,2,'.','')}</td>
												</tr>
												{is_igst ? <tr>
													<td className="pb-0">VAT:</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(parseFloat(order_data.sub_total*order_data.vat_amount/100),2,'.','')}</td>
												</tr>:<Fragment>
													<tr>
														<td className="pb-0">{is_utgst ? "UTGST":"SGST"} :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(sgst_tax,2,'.','')}</td>
													</tr>
													<tr>
														<td className="pb-0">CGST :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(cgst_tax,2,'.','')}</td>
													</tr>
												</Fragment>}
												{/*<tr>
													<td className="pb-0">Discount :</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(p_discount+parseFloat(order_data.auto_apply_discount)+parseFloat(order_data.coupon_discount)+parseFloat(order_data.manual_discount_value)+parseFloat(order_data.point_discount),2,'.','')}</td>
												</tr>*/}
												{/* {parseFloat(order_data.return_amount)!=0 ? <tr className="font-weight-bold">
													<td className="pb-0">Discount :</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(parseFloat(order_data.discount),2,'.','')}</td>
												</tr>:""} */}
												<tr className="font-weight-bold">
													<td className="pb-0">Grand Total :</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(parseFloat(order_data.grand_total),2,'.','')}</td>
												</tr>
											</tbody>:""}
										</Table>
										<div className="border-bottom pt-2 mb-3" />
									</div>
										{order_data.delivery_date!=0 ? <h4>Delivery Date  : {moment(order_data.delivery_date*1000).format('MM/DD/YYYY')}</h4>:""}
									</div>
								</Colxx>
							</Row>
							
							{/* <div >
								<hr/>
								<Row>
									<Colxx md="12">
										<AvGroup>
											<Label>Payment Mode<span className="text-danger">*</span></Label>
											<AvField
												id="payment_mode"
												name="payment_mode"
												type="select"
												autoComplete="off"
												validate={{
													required: {
														value: true,
														errorMessage: "This field is required."
													}
												}}
											>
												<option value="">-- Select Payment Mode --</option>
												{CUSTOMER_ORDER_PAYMENTS.map(row=>{
													return(<option value={row.value} key={row.key} >{row.label}</option>)
												})}
											</AvField>
										</AvGroup>
									</Colxx>
								</Row>
							</div> */}


							<Row className="big-btn-font mb-3">
										<Colxx lg="12">
											<div className="mt-2 mb-1 "> <h3><strong>Payments</strong></h3></div>
												<div className="">
													<Table responsive bordered className="special-table-layout form-group-mb-0">
														<thead>
														  <tr>
															<td>Sl.no.</td>
															<td>Payment Mode</td>
															<td>Amount</td>
															<td>Action</td>
														  </tr>
														</thead>
														<tbody>
															{order_payment_splits.map((row,index)=>{
				
																remaining_payment-=parseFloat(row.amount!="" ? row.amount:"0");
																remaining_payment = parseFloat(parseFloat(remaining_payment).toFixed(2))
										
																return <tr key={index} valign="center">
																	<td>
																		<div className="d-flex justify-content-center align-items-center" style={{minHeight:'72px'}}>
																			{index+1}
																		</div>
																	</td>
																	<td>
																		<AvGroup className="mb-0">
																			<AvField
																				id={"payment_mode_"+index}
																				name={"payment_mode_"+index}
																				type="select"
																				value={row.payment_mode!="0" ? row.payment_mode:""}
																				// value={ row.payment_mode=="Credit"?0:row.amount}
																				readOnly={row.payment_mode=="Credit"?true:false}
																				autoComplete="off"
																				validate={{
																					required: {
																						value: true,
																						errorMessage: "This field is required."
																					}
																				}}
																				onChange={(e)=>{
																					let order_payment_splits = this.state.order_payment_splits.map(p=>{
																						if(row.id==p.id){
																							p.payment_mode=e.target.value;
																						}
																						return p;
																					});
																					this.setState({ order_payment_splits })
																				}}
																			>
																				<option value="">-- Select Payment Mode --</option>
																				{CUSTOMER_ORDER_PAYMENTS.map(row=>{
																					return(<option value={row.value} key={row.key} >{row.label}</option>)
																				})}
																			</AvField>
																		</AvGroup>
																	</td>
																	<td>
																		<AvGroup className="mb-0">
																			<AvField
																				id={"payment_amount_"+index}
																				name={"payment_amount_"+index}
																				type="number"
																				value={ row.payment_mode=="Credit"?"0":row.amount}
																				readOnly={row.payment_mode=="Credit"?true :false}
																				autoComplete="off"
																				
																				validate={{
																					
																					required: {
																						value: true,
																						errorMessage: "This field is required."
																					},
																					min:{
																						value: "0",
																						errorMessage: "Please enter a value greater than or equal to 0"
																					}
																				}}
																				onChange={(e)=>{
																					let order_payment_splits = this.state.order_payment_splits.map(p=>{
																						if(row.id==p.id){
																							p.amount=e.target.value;
																						}
																						
																						return p;
																					});
																					this.setState({ order_payment_splits })
																				}}
																			/>
																		</AvGroup>
																	</td>
																	<td >
																		{order_payment_splits.length!=1 && row.id.length>11 ? <div className="d-flex justify-content-center align-items-center" style={{minHeight:'72px'}}>
																			<span className="text-danger" onClick={()=>{ this.removePaymentRow(row.id) }}><i className="fas fa-2x fa-times" /></span>
																		</div>:""}
																	</td>
																  </tr>
															})}
														</tbody>
													</Table>
													
													<div className="d-flex justify-content-between">
														{remaining_payment>0 ? <Button type="button" color="primary" size="sm" onClick={this.addPaymentRow}>Add Payment</Button>:""}
														<div className="text-right w-100">
															<h5 className="text-danger">Due Amount: {this.state.currency_symbol}{parseFloat(parseFloat(remaining_payment).toFixed(2)).toFixed(2)}</h5>
														</div>
													</div>
													
													{remaining_payment<0 ? <div className="text-right">
														<h3 className="text-danger">Over Paid</h3>
													</div>:""}
													
												</div>
										</Colxx>
									</Row>

								
						</Fragment>:""}
   
		
									

					</ModalBody>
					<ModalFooter>
					{/* onClick={handleClose}  */}
						<Button color="primary" id="make-payment" type="submit"  disabled={remaining_payment<0} onClick={()=>{
							this.setState({due_amount: remaining_payment})
						}
							
						}>
							<IntlMessages id="customer.submit" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
