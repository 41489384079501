import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import moment from 'moment';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditVehicleModal  extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {			
		vehicle:null		
	};
	
  }

	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let vehicle; 					
            if (prevProps.vehicle_id!== this.props.vehicle_id && this.props.vehicle_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('vehicle_id',this.props.vehicle_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'customer/get-vehicle-by-id',formData).then((res)=>{
					
					var data = res.data;
					vehicle=data.vehicle;
					
					let customer_id = this.props.customers.find(r=>{ return vehicle.customer_id==r.id });
					
					this.setState({ 
						vehicle,
						customer_id
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    } 
  
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-vehicle").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("vehicleEditForm"));
			formData.append('vehicle_id',this.props.vehicle_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'customer/edit-vehicle',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-vehicle").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-vehicle").disabled=false;
			});

		}
	}  

  
  render() {

		if(this.state.vehicle!=null){
			var vehicle=this.state.vehicle;
		}else{
			var vehicle;
		}  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Vehicle
		  </ModalHeader>
			<AvForm
				id="vehicleEditForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Customer<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="customer_id"
								id="customer_id"
								placeholder="--Select Customer--"
								options={this.props.customers}						
								value={this.state.customer_id}
								onChange={customer_id => { this.setState({ customer_id }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Number<span className="text-danger">*</span></Label>
						  <AvField
							id="plate"
							name="no_plate"
							type="text"
							autoComplete="off"
							value={ vehicle?((vehicle.no_plate!=null)?vehicle.no_plate:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
							
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Emirate<span className="text-danger">*</span></Label>
						  <AvField
							id="emirate"
							name="emirate"
							type="text"
							autoComplete="off"
							value={ vehicle?((vehicle.emirate!=null)?vehicle.emirate:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
							
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Brand Name<span className="text-danger">*</span></Label>
						  <AvField
							id="make"
							name="vehicle_make"
							type="text"
							autoComplete="off"
							value={ vehicle?((vehicle.vehicle_make!=null)?vehicle.vehicle_make:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
							
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Model Name<span className="text-danger">*</span></Label>
						  <AvField
							id="model"
							name="vehicle_model"
							type="text"
							autoComplete="off"
							value={ vehicle?((vehicle.vehicle_model!=null)?vehicle.vehicle_model:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Year<span className="text-danger">*</span></Label>
						  <AvField
							id="vehicle_year"
							name="vehicle_year"
							type="number"
							autoComplete="off"
							value={ vehicle?((vehicle.vehicle_year!=null)?vehicle.vehicle_year:''):'' }
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},
								min:{
									value: "1000",
									errorMessage: "Please enter a value greater than or equal to 1000"
								},
								max:{
									value: moment().format('YYYY'),
									errorMessage: "Please enter a value less than or equal to "+moment().format('YYYY')
								},
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Chassis Number<span className="text-danger">*</span></Label>
						  <AvField
							id="chaisis_number"
							name="chaisis_number"
							type="text"
							autoComplete="off"
							value={ vehicle?((vehicle.chaisis_number!=null)?vehicle.chaisis_number:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
			
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-vehicle" >
				<IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditVehicleModal ;
