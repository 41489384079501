import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES, OUNCE_CONSTANT } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { config } from "react-transition-group";
import Switch from "rc-switch";
import swal from "sweetalert";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddFinishedProductModal extends Component{
	constructor(props) {
		super(props);
		
		this.state={
			fill_variation:null,
			fill_variation_id:null,
			no_of_finished_product:1,
			config:[],
			manual_barcode:false,
			serialNo:[],
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {		
		var fill_variation = null;
		var fill_variation_id = null;
		// var no_of_finished_product=this.state.no_of_finished_product
		// var config=this.state.config
		// this.setState({no_of_finished_product,config}); 
		{console.log('Product@@@',this.props.product);}
		
		if(this.props.product_variations.length){
			fill_variation=this.props.product_variations[0];
			fill_variation_id = fill_variation.variation_id;
			
		}
		const id=0;
		this.setState({ fill_variation, fill_variation_id}); 
		
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			//document.getElementById("add-finished-product").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("addFinishedProductForm"));
			formData.append('facility_id',facility_id);
			formData.append('product_id',this.props.product.id);
			formData.append('package_type',2);
			formData.append('user_id',user_id);
			formData.append('barcode',this.props.product.barcode);
			
			axios.post(REST_API_END_POINT+'inventory/add-finished-package',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==0){
					swal("Warning!",data.msg,"warning");
				return ;
				}
				if(data.status==1){						
					var api_message=document.getElementById("api_message_finished_products");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("add-finished-product").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
				this.setState({manual_barcode:false})
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-finished-product").disabled=false;
			});
		}
	}
	change_data=(e)=>{
		
		const config=[]
		const k=e
		for(let i=1;i<=k;i++){
			
			
		config.push({ id:'IMENo'+i, name: 'imeno', type: 'text', required: true })
		}
		
		this.setState({config})
	
	}
	
	
	render() {
		const { no_finished } = this.state;
	
		const config=this.state.config
		return (
			  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add Finished Products
				</ModalHeader>
				<AvForm
					id="addFinishedProductForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Variation<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="finished_variation_id"
									name="finished_variation_id"
									type="select"
									value={this.state.fill_variation_id}
									onChange={e=>{
										var fill_variation = this.props.product_variations.find(r=>{ return r.variation_id==e.target.value });
										if(fill_variation!=null){
											this.setState({ fill_variation_id:fill_variation.variation_id,fill_variation });
										}else{
											this.setState({ fill_variation_id:null,fill_variation:null });
										}
									}}
								>
								{this.props.product_variations.map((row,index)=>{
									return <option value={row.variation_id} key={index} >{row.variation_name}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>No. Finished Product<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="no_packages"
									name="no_packages"
									type="number"
									autoComplete="off"
									value={""}
									// onChange={(e)=>{ this.setState({ no_of_finished_product:e.target.value })
									onChange={(e)=>{ this.change_data(e.target.value) }
								}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: "1",
											errorMessage: "Please enter a value greater than or equal to 1"
										}
									}}
								/>
							</AvGroup>
							{console.log('No 0f packages')
							}
						</Colxx>

						{this.props.product.unique_id==1?config?
						config.map(input => {
							
					return(<>
					
					<Colxx lg="3">
					<Label>Serial No:<span className="text-danger"></span></Label>
					{/* <Label>{input.id}:<span className="text-danger"></span></Label>	 */}
					</Colxx>
					<Colxx lg="9">
						<AvGroup >								
							<AvField
								id={input.id}
								name={input.id}
								type="text"
								autoComplete="off"
								value={""}
							 
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}

								
									
								}}
							/>
						</AvGroup>
					
				</Colxx>
				</>
					)}):"":""}
					
					</Row>
					{/* <Row>
					<Colxx lg="3"  >
						<Label  className="mr-1">	Manual Barcode	</Label>										  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.manual_barcode}
								onChange={status => { this.setState({ manual_barcode:!this.state.manual_barcode}) }}								
							/>
							
						
					</Colxx>
					{this.state.manual_barcode?<Colxx lg="9">
						<AvGroup className="error-t-negative">
						
						  <AvField							
							name="barcode"
							type="number"
							autoComplete="off"
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},
								minLength: {
									value: 13,
									errorMessage: "The code must has 13 characters"
								},
								maxLength: {
									value: 13,
									errorMessage: "The code never exceed than 13 characters"
								}
							}}
						  />
						</AvGroup>
					</Colxx>:""}
					</Row> */}
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="add-finished-product" >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};