import React, { Component, Fragment } from "react";
import {
	Row,Input,FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";
import { Colxx } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
  
export default class RedeemPointModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			points : 0,
			point_discount : 0,
			data:[]
		};	
	}
	
	componentDidMount(){
		const payment_data = this.props.payment_data;
		var points = this.state.points;
		var point_discount = this.state.point_discount;
		var data = this.props.price_points;
		
		if(payment_data!=null){
			points = payment_data['points'];
			point_discount = payment_data['point_discount']/this.props.currency_rate;
		}
		this.setState({	data, points, point_discount });
	}
	
	handleSubmit(points,point_discount) {
		var payment_data = this.props.payment_data;
		
		payment_data['points'] = points;
		payment_data['point_discount'] = parseFloat(point_discount*this.props.currency_rate);
		
		this.props.updatePaymentData(payment_data);
		this.props.recalculateTotals();
		this.props.toggleModal();
	}

	render() {
	  
	    let data = JSON.parse(JSON.stringify(this.state.data));
		if (this.state.search) {
			data = data.filter(row => {
				return row.price_points.includes(this.state.search) || row.discount.includes(this.state.search)
			})
		}
		
		const currency_symbol = this.props.currency_symbol;
		const currency_rate = this.props.currency_rate;
		
		const dataTableColumns = [
			{
				Header: "Customer Points",
				accessor: "price_points",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Discount",
				accessor: "discount",
				Cell: props => <p className="text-muted">{currency_symbol}{" "+(props.value*currency_rate).toFixed(2)}</p>
			},
			{
				Header: "Options",
				accessor: "price_points",
				Cell: props => <Button size="xs" color="success" onClick={()=>{ this.handleSubmit(props.original.price_points,props.original.discount) }} >{this.state.points==props.original.price_points && this.state.point_discount==props.original.discount ? "Applied":"Apply"}</Button>
			}		  
		];
	  
		return (
	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Customer Points
				</ModalHeader>
					
				<ModalBody>
					{this.state.data.length==0 ? <div className="text-center h4 text-danger mb-2">Sorry! the customer have insufficient points to redeem</div>:"" }
					<Row>          
						<Colxx xxs="12">
							<div className="mb-4 dropdown-edit">					
								<FormGroup row>
									<Colxx lg="12">
										<Input
											type="text"
											name="table_search"
											id="table_search"
											placeholder="Search"
											value={this.state.search}
											onChange={e => this.setState({search: e.target.value})}
										/>
									</Colxx>
								</FormGroup>
							</div>

							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={5}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={false}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>		
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
	}
};
