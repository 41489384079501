import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { zeroPad, getCurrencySymbol, getCustomerWord } from "../../../../helpers/Utils";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

const payment_methods=[
	{ label:"--All--", value:"0", key:0 },
	{ label:"Cash", value:"Cash", key:1 },
	{ label:"Debit Card", value:"Debit Card", key:2 },
	{ label:"Check", value:"Check", key:3 },
	{ label:"Delivery", value:"Delivery", key:4 },
	{ label:"Express", value:"Express", key:5 }
]

export default class Payments extends Component {
	
	constructor(props) {
		super(props);
		
		let currencySymbol = getCurrencySymbol(false);
		this.state = {		 
			reports:[],
			authorize_bys:[],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			customer_name: { label:"--Select Supplier Name--", value:0, key:0 },
			customer_names:[],
			blocking: true,
			currencySymbol,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}

		if(this.state.customer_name!=null){
			formData.append('customer_name',this.state.customer_name.value);
		}else{
			formData.append('customer_name',0);
		}
		
		axios.post(REST_API_END_POINT+'report/vendor-payments-against-vehicles',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				var customer_names = data.customer_names;
				customer_names = customer_names.map(row=>{
					row.label=row.vendor_name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				customer_names.unshift({ label:"--Select Supplier Name--", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					customer_names,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		for(let row of reports){
			formated_data.push([
				zeroPad(row.order_number,6),
				moment(row.timestamp*1000).format('MM/DD/YYYY'),
				row.vendor_name,
				row.vehicle_name,
				this.state.currencySymbol+row.grand_total,
				row.payment_mode!=0 ? row.payment_mode:"",
				row.payment_mode!=0 ? "Paid":"Unpaid"
			])
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		const { customer_names } = this.state;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.vendor-payments-against-vehicle" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="6">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							
							<Colxx lg="6">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							
							<Colxx lg="6">
								<Label>Vendor Name</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="customer_name"
									id="customer_name"
									placeholder="All"
									value={this.state.customer_name}						
									options={customer_names}
									onChange={customer_name => { this.setState({ customer_name }) }}
								/>
							</Colxx>
							
							<Colxx lg="6">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Invoice #</th>
												<th>Invoice Date</th>
												<th>Supplier Name</th>
												<th>Vehicle</th>
												<th>Total Amount</th>
												<th>Payment Mode</th>
												<th>Payment Status</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
