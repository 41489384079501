import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { Row, Card, CardHeader, Button, Select, CardBody, FormGroup, Input, CustomInput, Badge, Label, Nav,NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import swal from 'sweetalert';
import classnames from "classnames";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import { Loader } from 'react-loaders';

import { uuid } from 'uuidv4';

import GetPackagesModal from "../../../containers/inventory-transfer/GetPackagesModal";
import ProductGrid from "../../../components/inventory-transfer/ProductGrid";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, OUNCE_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";
import moment from "moment";

import { getCookie, setCookie } from "../../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class InventoryTransfer extends Component {
	constructor(props) {
		super(props);
		
		this.state = {			
		    activeFirstTab: "1",		 
			modalOpenGetPackages: false,
			data:[],
			search: '',
			
			blocking:true,
			
			transfer_facility_id:0,
			product_id:0,
			products: [],
			categories: [],
			cart: [],
			cart_totals:{
				subtotal:0,
				tax:0,
				total:0,				
			},
			facility:null,
			currency_symbol:"AED",
			currency_rate:1,
		};
		
		this.toggleModalGetPackages = this.toggleModalGetPackages.bind(this); 
		this.addToCart = this.addToCart.bind(this); 
		this.recalculateTotals = this.recalculateTotals.bind(this); 
		this.checkOut = this.checkOut.bind(this); 
		this.toggleFirstTab = this.toggleFirstTab.bind(this); 

	}
	
	componentDidMount(){  
		const user_id = localStorage.getItem('user_id');
		var transfer_facility_id = getCookie('transfer_facility_id','string');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);
        formData.append('transfer_facility_id',transfer_facility_id);
		
		axios.post(REST_API_END_POINT+'inventory-transfer/transfer',formData).then((res)=>{
			var data = res.data;
		
			if(data.facility){
				var cart = getCookie('transfer_'+transfer_facility_id,'object');
				
				if(cart==null){
					cart=[];
				}
								
				this.setState({
					products: data.products,
					categories: data.categories,
					facility: data.facility,
					transfer_facility_id: transfer_facility_id,
					blocking:false,
				},()=>{
					if(cart!=null){
						this.recalculateTotals(cart);
					}
				});
			}else{
				swal("Warning!",data.msg,"warning").then(()=>{
					window.location="/app/inventory/select-facility";
				});
				setTimeout(()=>{
					window.location="/app/inventory/select-facility";
				},4000)
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	addToCart(values) {
        const user_id = localStorage.getItem('user_id');
		const transfer_facility_id = this.state.transfer_facility_id;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
				
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);		
        formData.append('transfer_facility_id',transfer_facility_id);
		
		for(let v in values){
			 formData.append(v,values[v]);
		}
		
		axios.post(REST_API_END_POINT+'inventory-transfer/add-to-cart',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				this.toggleModalGetPackages();
				
				var cart_item = data.cart_item;				
				var cart = this.state.cart;
				
				cart.push(cart_item);
				
				this.setState({ cart },()=>{ this.recalculateTotals(); });
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
    }
	
	removeCart(insert_id){
		var cart = this.state.cart;
		cart = cart.filter(row=>{ return row.insert_id!=insert_id });		
		this.recalculateTotals(cart);
	}
	
	async recalculateTotals(cart=null){
		const transfer_facility_id = this.state.transfer_facility_id;
		if(cart==null){
			cart=this.state.cart;
		}
		
		var cart_totals = {
			subtotal:0,
			tax:0,
			total:0,
		}
		
		for(let key in cart){
			let row = cart[key];		
			
			let unit_sales_tax = JSON.parse(JSON.stringify(row.unit_sales_tax))*row.unit_price;
			
			if(row.unit_price!=0){					
				row.price = row.unit_price+unit_sales_tax;
			}
			
			row.sales_tax = parseFloat((row.qty*unit_sales_tax).toFixed(2));
			row.tax = row.sales_tax;
			cart_totals.tax+=row.tax;
			
			cart_totals.subtotal += parseFloat((row['unit_price']*row['qty']).toFixed(2));
			cart_totals.total += parseFloat((row['unit_price']*row['qty']+row.tax).toFixed(2));
			
			cart[key] = row;
		}
				
		this.setState({ cart, cart_totals });
		setCookie('transfer_'+transfer_facility_id,cart);
	}
	
	checkOut = (place_order) =>{
		var cart = this.state.cart;
		const transfer_facility_id = this.state.transfer_facility_id;
		if(cart.length){
			/*let proceed=true;
			for(let row of cart){
				if(parseInt(row.max_quantity)<row.qty){
					proceed=false;
					break;
				}
			}
			if(proceed){*/
				const currency_rate = this.state.currency_rate;
				const currency_code = this.state.currency_code;
				
				const cart_totals = this.state.cart_totals;
				cart = JSON.parse(JSON.stringify(this.state.cart));
				
				const user_id = localStorage.getItem('user_id');
				const customer_id = this.state.customer_id;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
								
				var formData = new FormData(); 
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);		
				formData.append('transfer_facility_id',transfer_facility_id);
				formData.append('cart_products',JSON.stringify(cart));
				formData.append('total_tax',cart_totals.tax);
								
				let req_url = 'inventory-transfer/place-order';
				if(!place_order){
					req_url = 'inventory-transfer/place-hold-order';
				}
				
				axios.post(REST_API_END_POINT+req_url,formData).then((res)=>{
					var data = res.data;
				
					if(data.status==1){
						setCookie('transfer_facility_id',null);
						setCookie('transfer_'+transfer_facility_id,null);
						this.setState({ order_id:data.order_id, success_msg:data.msg });
						swal("Success!",data.msg,"success").then(()=>{
							window.location="/app/inventory/select-facility";
						});
					}else{
						swal("Warning!",data.msg,'warning');
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			/*}else{
				swal("Warning!","Please check the quantity in cart","warning");
			}*/
		}else{
			swal({
			  title: "Are you sure?",
			  text: "There is no products in the cart",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
				if (willDelete) {
					setCookie('transfer_facility_id',null);
					setCookie('transfer_'+transfer_facility_id,null);
					window.location="/app/inventory/select-facility";
				}else{
					swal("Continue ordering!");
				}
			});
		}
	} 
	
	toggleModalGetPackages = (e,product_id=0) => {
		this.setState({
			product_id,
			modalOpenShowProducts:false,
			modalOpenGetPackages: !this.state.modalOpenGetPackages
		});
	};
	
	render() {
		const { categories, products, cart, cart_totals, facility } = this.state;
		let data = JSON.parse(JSON.stringify(this.state.cart));
	  
		if (this.state.search) {
			data = data.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Product",
				accessor: "name",
				Cell: (props) => {
					const row = props.original;
					const variations = row.variations;
					var cart_row='';
					
					if(parseInt(props.original.max_quantity)<=0){
						cart_row=<Fragment><br/><span className="text-danger">Out of Stock. Please Remove this item.</span></Fragment>
					}else{
						cart_row=<Fragment><br/><span className="text-danger">Only {parseInt(props.original.max_quantity)} is left in stock</span></Fragment>;
					}
								
					return(<p className="list-item-heading mt-1">{props.value}{cart_row}</p>)
				}
			},
			{
				Header: "Quantity",
				accessor: "qty",
				width:110,
				Cell:  (props) => {
							let variation = props.original.variations.find(v=>{ return props.original['options']['variation_id']==v['variation_id'] });
							let variation_name='';
							if(variation!=null){
								variation_name = variation.variation_name;
							}
							return(<Fragment>
								<AvField
									name={"quantity_"+props.original.insert_id}
									id={"quantity_"+props.original.insert_id}
									type="number"
									className="w-100 mt-1" 
									value={props.value.toString()}
									min="0"
									onChange={(e)=>{
										const value = e.target.value;										
									}}
									onBlur={(e)=>{
										const value = e.target.value;
										var cart = this.state.cart;

										if(value!='' && parseFloat(value)>=0 && parseFloat(value)<=parseFloat(props.original.max_quantity) ){	
											cart = cart.map(r=>{
												if(r.insert_id==props.original.insert_id){
													r.qty=parseFloat(e.target.value)
												}
												return r;
											});
										}
										this.recalculateTotals(cart);
									}}
								/>{props.original['is_finished']==1 ? variation_name:""}
				            </Fragment>)
				}							
			},
			{
				Header: "Unit Price",
				accessor: "unit_price",
				width:110,
				Cell:  (props) => {
							return(<Fragment>
								<AvField
									name={"unit_price_"+props.original.insert_id}
									id={"unit_price_"+props.original.insert_id}
									type="number"
									className="w-100 mt-1" 
									value={props.value.toString()}
									min="0"
									onChange={(e)=>{
										const value = e.target.value;										
									}}
									onBlur={(e)=>{
										const value = e.target.value;
										var cart = this.state.cart;

										if(value!=''){	
											cart = cart.map(r=>{
												if(r.insert_id==props.original.insert_id){
													r.unit_price=parseFloat(e.target.value)
												}
												return r;
											});
										}
										this.recalculateTotals(cart);
									}}
								/>
				            </Fragment>)
				}							
			},
			{
				Header: "Price",
				accessor: "price",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat(row['unit_price']*row['qty']).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
				}
			},	  	  
			{
				Header: "Sales Tax",
				accessor: "sales_tax",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat(row['sales_tax']).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
				}
			},
			{
				Header: "Amount",
				accessor: "price",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat(row['unit_price']*row['qty']+row['exise_tax']+row['sales_tax']).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
				}
			},
			{
				Header: "",
				accessor: "insert_id",
				width:60,
				Cell: props =><Fragment>
								<span className="pl-1 pr-1 text-danger cursor-pointer cart-op-icons" onClick={(e)=>{ this.removeCart(props.value) }} ><i className="fa fa-times" /></span>
							</Fragment>
			}
		];
		
    return (
      <Fragment>
        <BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
			<Card className="p-2">
				<Row className="align-items-center text-danger">
					<Colxx lg="5">
						<h2 className="font-weight-bold">{facility!=null ? "Transfer packages to "+facility.facility_name:""}</h2>
					</Colxx>
					<Colxx lg="7">
						<div className="clearfix">
							<div className="float-sm-right ml-2 mr-2">
								<NavLink href={"/app/inventory/select-facility"} className="btn btn-sm btn-primary mt-1" >Back</NavLink>
							</div>
						</div>
					</Colxx>
				</Row>	 
			</Card>
			<Row>
				<Colxx lg="5">
					<Card className="mt-3">
						<CardHeader>
							<Nav tabs className="card-header-tabs ">
								{categories.map((row,index)=>{
									return(<NavItem className="cursor-pointer" key={index}>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === (index+1).toString(),
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab((index+1).toString());
											}}
											to="#"
										>
											{row.name}
										</NavLink>
									</NavItem>)
								})}
							</Nav>
						</CardHeader>
						
						<div className="pos-product-wrapper">
							{categories.map((row,index)=>{
								const filtered_products = products.filter(r=>{ return r.category_id==row.category_id })
								return(<TabContent activeTab={this.state.activeFirstTab} key={index}>
									<TabPane tabId={(index+1).toString()}>
										<ProductGrid 
											products={filtered_products}
											toggleModalGetPackages={this.toggleModalGetPackages}
										/>
									</TabPane>
								</TabContent>)
							})}
						</div>
					</Card>
				</Colxx>
				<Colxx lg="7" >
					 <Card className="mt-3">
						<div className="mt-4 mb-1 ml-4 mr-4"> <h3><strong>Order for {facility!=null ? facility.facility_name:""}</strong></h3></div>
						<div className="mb-4 pt-4 pb-4 pr-2 pl-2 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value})}
									/>
							  </Colxx>
							</FormGroup>			
							<AvForm
								className="av-tooltip tooltip-right-bottom compact-cart-form"
								onSubmit={()=>{}}
							>
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={10}
									minRows = {2}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "send_on",desc: false}]}
								/>
							</AvForm>
						</div>
						<Row className="text-right">
							<Colxx lg="12">
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2"><span className="font-weight-bold">Sub - Total </span>: {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.subtotal*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">TAX </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.tax*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">Grand Total </span>: {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.total*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
							</Colxx>
						</Row>
						
						<Row className="pl-2 big-btn-font pr-2">
							<Colxx lg="6" className="mb-4">
								<Button block size="sm" color="success" onClick={()=>{ this.checkOut(false) }} >Hold Order</Button>
							</Colxx>
							<Colxx lg="6" className="mb-4">
								<Button block size="sm" color="success" onClick={()=>{ this.checkOut(true) }} >Place Order</Button>
							 </Colxx>
						</Row>
					</Card>
				</Colxx>
			</Row>

			<GetPackagesModal
				modalOpen={this.state.modalOpenGetPackages}
				toggleModal={this.toggleModalGetPackages}
				addToCart={this.addToCart}
				product_id={this.state.product_id}
			/>
			
		</BlockUi>
      </Fragment>
    );
  }
}
