import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { zeroPad, getCurrencySymbol, getCustomerWord } from "../../../../helpers/Utils";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class SalesByRefund extends Component {
	
	constructor(props) {
		super(props);
		
		let currencySymbol = getCurrencySymbol(false);
		this.state = {		 
			reports:[],
			products:[],
			customer_names:[],
			authorize_bys:[],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			product_id: { label:"All", value:0, key:0 },
			customer_name: { label:"--Select "+customerWord+" Name--", value:0, key:0 },
			authorize_by: { label:"--Select Authority--", value:0, key:0 },
			blocking: true,
			final_tax_split_up:{},
			total:0,
			totalRefund:0,
			currencySymbol,
			types:[],
			type: { label:"All", value:2, key:2 },
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}

		if(this.state.product_id!=null){
			formData.append('product_id',this.state.product_id.value);
		}else{
			formData.append('product_id',0);
		}

		if(this.state.customer_name!=null){
			formData.append('customer_name',this.state.customer_name.value);
		}else{
			formData.append('customer_name',0);
		}
		
		if(this.state.authorize_by!=null){
			formData.append('authorize_by',this.state.authorize_by.value);
		}else{
			formData.append('authorize_by',0);
		}
		if(this.state.type!=null){
			formData.append('payment_status',this.state.type.value);
		}else{
			formData.append('payment_status',2);
		}

		
		axios.post(REST_API_END_POINT+'report/refunded-sales',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				var products = data.products;
				products = products.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				products.unshift({ label:"All", value:0, key:0 });
				
				var customer_names = data.customer_names;
				customer_names = customer_names.map(row=>{
					row.label=row.vendor_name;
					row.value=row.vendor_id;
					row.key=row.vendor_id;
					return row;
				});
				customer_names.unshift({ label:"--Select "+customerWord+" Name--", value:0, key:0 });
				
				var authorize_bys = data.authorize_bys;
				authorize_bys = authorize_bys.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				var types=[{label:"All",value:2,key:2},{label:"Paid",value:1,key:1},{label:"UnPaid",value:0,key:0}]
				authorize_bys.unshift({ label:"--Select Authority--", value:0, key:0 });
				
				let total = 0
				let totalRefund = 0
				let reports = data.reports;
				let final_tax_split_up={
					'TAX':{
						name:'TAX',
						amount:0
					}
				};
				reports = reports.map(row=>{
					let tax_split_up = [];
					
					/*let is_utgst=false;
					let is_igst = row.customer_state_id !== data.facility.facility_state_id;
					if(!is_igst){
						is_utgst = row.is_utgst=="1";
					}
					
					if(!is_igst){
						if(row.tax_split_up!=null){
							let tax_split_up_a = JSON.parse(row.tax_split_up);
							for(let t in tax_split_up_a){
								if(is_utgst && tax_split_up_a[t].name=='SGST'){
									tax_split_up_a[t].name='UTGST';
								}
								tax_split_up.push(tax_split_up_a[t].name+': '+this.state.currencySymbol+''+tax_split_up_a[t].amount);
								if(typeof final_tax_split_up[t]=='undefined'){
									final_tax_split_up[t]=tax_split_up_a[t];
									final_tax_split_up[t].amount = parseFloat(final_tax_split_up[t].amount);
								}else{
									final_tax_split_up[t].amount+=parseFloat(tax_split_up_a[t].amount);
								}
							}
						}
					}else{*/
						tax_split_up.push('TAX: '+this.state.currencySymbol+''+row.total_tax);
						final_tax_split_up['TAX'].amount+=parseFloat(row.total_tax);
					//}
					row.tax_split_up = tax_split_up;
					
					
					if(row.payment_mode!="Credit"){
					total+=parseFloat(row.return_amount);	
					
				 }
					return row;
				});
				
				this.setState({
					reports,
					products,
					customer_names,
					authorize_bys,
					final_tax_split_up,
					total,
					totalRefund,
					types,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					products: [],
					customer_names: [],
					authorize_bys: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){	
		let total = this.state.total;
		const currencySymbol = this.state.currencySymbol;
		let final_tax_split_up = this.state.final_tax_split_up;
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', {
						extend: 'pdfHtml5',
						orientation: 'landscape',
						pageSize: 'A4',
						customize: function ( doc ) {
							// place the content in after the table
							let i=3;
							// doc.content.splice( i++, 0, {
							// 	margin: [ 0, 10, 0, 2 ],
							// 	alignment: 'left',
							// 	text: [{ text: 'Total Tax Split ', fontSize: 15 }]
							// });
							for(let t in final_tax_split_up){
								// doc.content.splice( i++, 0, {
								// 	margin: [ 0, 1, 0, 0 ],
								// 	alignment: 'left',
								// 	text: [{ text:'Total VAT'+': '+currencySymbol+''+final_tax_split_up[t].amount.toFixed(2), fontSize: 15 }]
								// });
							}
							doc.content.splice( i++, 0, {
								margin: [ 0, 10, 0, 0 ],
								alignment: 'left',
								text: [{ text: 'Refunded Amount: '+currencySymbol+total.toFixed(2), fontSize: 15 }]
							});
							
						}
					},
					{
						extend: 'print',
						customize: function ( win ) {
							// $(win.document.body)
							// 	.append('<h4 style="margin-top:15px;"><strong>Total Tax Split </strong></h4>');
							for(let t in final_tax_split_up){
								// $(win.document.body)
								// .append('<h4 style="margin-top:15px;"><strong>'+"Total VAT"+': '+currencySymbol+''+final_tax_split_up[t].amount.toFixed(2)+'</strong></h4>');
							
							//.append('<span style="margin-bottom:5px;">'+final_tax_split_up[t].name+': '+currencySymbol+''+final_tax_split_up[t].amount.toFixed(2)+'</span>');
						
						}
							$(win.document.body)
								.append('<h4 style="margin-top:15px;"><strong>Refunded Amount: '+currencySymbol+total.toFixed(2)+'</strong></h4>');
						}
					}
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		for(let row of reports){			
			formated_data.push([
				zeroPad(row.order_number,6),
				moment(row.timestamp*1000).format('MM/DD/YYYY'),
				row.customer_name,
				row.authorized_by,
				row.list.split(",").join("<br/><br/><br/>"),
				
				// row.unique_code,
				// this.state.currencySymbol+row.sub_total,
				// this.state.currencySymbol+row.total_tax,
				// row.tax_split_up.join(",<br/>"),
				// this.state.currencySymbol+(parseFloat(row.coupon_discount)+parseFloat(row.manual_discount)).toFixed(2),
				// this.state.currencySymbol+row.total,
			
				moment(row.paid_on*1000).format('MM/DD/YYYY'),
				row.payment_mode!=0 ? row.payment_mode:"",
				row.payment_status,
				row.return_amount,
				row.return_date!=0?moment(row.return_date*1000).format('MM/DD/YYYY'):"",
				row.comment==null?"N/A":	row.comment,
			])
		}
		return formated_data;
	}
  
	render() {	
		const reports = this.state.reports;
		const { products, customer_names, authorize_bys,types } = this.state;
		let final_tax_split_up = this.state.final_tax_split_up;
		let tax_totals=[];
		for(let t in final_tax_split_up){
			tax_totals.push(<span className="mb-0" key={t}><span dangerouslySetInnerHTML={{ __html: this.state.currencySymbol}} />{final_tax_split_up[t].amount.toFixed(2)}</span>)				
			// tax_totals.push(<p className="mb-0" key={t}>{final_tax_split_up[t].name+': '}<span dangerouslySetInnerHTML={{ __html: this.state.currencySymbol}} />{final_tax_split_up[t].amount.toFixed(2)}</p>)				
		}
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="Refund Sales Report" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="4">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							<Colxx lg="4 d-none">
								<Label>Product</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="product_id"
									id="product_id"
									placeholder="All"
									value={this.state.product_id}						
									options={products}
									onChange={product_id => { this.setState({ product_id }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>{customerWord} Name</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="customer_name"
									id="customer_name"
									placeholder="All"
									value={this.state.customer_name}						
									options={customer_names}
									onChange={customer_name => { this.setState({ customer_name }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Account Owner</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="authorize_by"
									id="authorize_by"
									placeholder="All"
									value={this.state.authorize_by}						
									options={authorize_bys}
									onChange={authorize_by => { this.setState({ authorize_by }) }}
								/>
							</Colxx>
							<Colxx lg="5">
								<Label>Type</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="type"
									id="type"
									placeholder="All"
									value={this.state.type}						
									options={types}
									onChange={type => { this.setState({ type }) }}
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Invoice</th>
												<th>Invoice Date</th>
												<th>{customerWord}</th>
												<th>Authorized By</th>
												<th>Products</th>
												
												{/* <th>Serial No</th> */}
												{/* <th>Sub Total</th> */}
												{/* <th>Total VAT</th> */}
												{/* <th>Tax Split Up</th> */}
												{/* <th>Discount</th> */}
												{/* <th>Total</th> */}
												<th>Paid On</th>
												<th>Payment Mode</th>
												<th>Payment Status</th>
												<th>Refunded Amount</th>
												<th>Refunded Date</th>
												<th>Refund Reason</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
								<div>
									{/* <h4><strong>Total VAT: {tax_totals} </strong></h4> */}
									
									<h4 className="mt-2"><strong>Refunded Amount</strong><strong>: <span dangerouslySetInnerHTML={{ __html: this.state.currencySymbol}} />{this.state.total.toFixed(2)}</strong></h4>
								    {/* <h4 className="mt-2"><strong>Refund Amount</strong>(Inc VAT)<strong>: <span dangerouslySetInnerHTML={{ __html: this.state.currencySymbol}} />{this.state.total.toFixed(2)}</strong></h4> */}
								</div>
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
