import React, { Component, Fragment } from "react";
import { Row, FormGroup, Label, Input, Button } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import { AvField, AvForm } from "availity-reactstrap-validation";

class FinishedProducts extends Component {
	constructor(props) {
		super(props);
	
		
		this.state = {
			search: '',
			uniqId:'',
			EmiId:[]
		};
		
		this.addToCart = this.addToCart.bind(this);
	
	  
	}
	
	
	addToCart(product_package){
		
		var product=this.props.product;
		var values={};
		console.log('EEEEE',product_package);
		
		
		values.product_id=product.id;
		values.product_price=product.product_price;
		values.product_unit_price=product_package.product_unit_price;		
		values.exise_tax=product.exise_tax;
		values.sales_tax=product.sales_tax;
		values.quantity=1;
		values.variation_id=product_package.variation_id;
		if(this.state.uniqId.length>0){
		values.uniqId=this.state.uniqId}
		else{
			values.uniqId=	product_package.opt
		}
		values.package_id=product_package.package_id;
		values.is_finished=1;
		values.cost_per_unit=product_package.cost_per_unit
		if(typeof this.props.deal!='undefined'){
			values.product_unit_price=this.props.deal.amount;
			values.deal_quantity=this.props.deal.quantity;
			values.name=this.props.deal.name;
			
		}
		
		console.log('Values',values);
		
		this.props.addToCart(values);		
		
	}
  
	render() {
		
		let data = this.props.finished_products;
		
	
	
		
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading w-100">{props.value}</p>
			},
			{
				Header: "Available",
				accessor: "available",
				Cell: props => <p className="text-muted w-100 mb-0 ">{props.value}</p>
			},
			{
				Header: "Choose ",
				accessor: "uniq_code",
				Cell:  (props) => {
			  
					let uniq_de=props.original.opt;
			       // this.setState({uniqId:uniq_code})
			
			return(<Fragment>
				 
				 				
				{props.original.uniq_code>0 ? uniq_de:
				 <AvForm>
				<AvField
				
					name={"uniq_code"}
					id={"uniq_code"}
					type="select"
					className="w-100 mt-1" 
					value={this.state.uniqId}
					onChange={e => this.setState({uniqId: e.target.value})}
					
				>
					{/* {props.original.uniq_code.map((v,i)=>{
						return (<option value={v.id} key={i}>{v.imeno}</option>)
						
					})}	 */}
						{
						
						props.original.uniq_code.map((v,i)=>{
						return (<option value={v.id} key={i}>{v.imeno}</option>)
						
					})}	
				
				
			
				
												
				</AvField>
				</AvForm>
				
				}
				
				
				
				
			</Fragment>
			)
			
			}	
			},
			{
				Header: "",
				width:50,
				//Cell: props => <Button size="xs" color="success" className="mb-0" onClick={e=>{ this.addToCart(props.original) }} ><i className="fa fa-plus" ></i></Button>
				Cell: props => <Button size="xs" color="success" className="mb-2" onClick={e=>{ this.addToCart(props.original) }} ><i className="fa fa-plus" ></i></Button>
			}
		];
		
		return (
			<Fragment>	  
				<Row>
					<Colxx lg="12">
						<div className=" pr-2 pl-2  dropdown-edit">						
							<FormGroup row className="mb-0">
								<Colxx lg="12">
									<Input
										type="text"
										name="table_search"
										id="finished_products_table_search"
										placeholder="Search"
										value={this.state.search}
										onChange={e => this.setState({search: e.target.value})}
									/>
								</Colxx>
							</FormGroup>
							
										
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={4}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={false}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default FinishedProducts;
