import {REST_API_END_POINT,dataEncryptKey} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const APPLY_COUPON = "APPLY_COUPON";
export const REMOVE_COUPON = "REMOVE_COUPON";

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  variationId
) => {
console.log('print',item);

  return dispatch => {
    if (addToast) {
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    }
	
	if(typeof item.variations!='undefined'){
		let variation = item.variations.find(r=>{ return r.variation_id==variationId });
		if(variation){
			item.product_unit_price = parseFloat(variation.unit_price);
			item.sales_tax = parseFloat(variation.tax);
			item.price = parseFloat(variation.price);
		}
	}
	
	
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,        
        variation_id: variationId        
      }
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item) => {	
	let variation;
		if(typeof item.variations!='undefined'){
			variation = item.variations.find(single => single.variation_id === item.variation_id);
		}else{
			variation = {real_stock:item.product_stock}
		}
	if(variation){
		return parseInt(variation.real_stock);
	}else{
		return 0;
	}
};

// apply coupon
export const ApplyCoupon = (couponCode, addToast) => {	
	return dispatch => {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('customer_id',0);
		formData.append('coupon',couponCode);
		axios.post(REST_API_END_POINT+'point-of-sale/get-applied-coupon-detail',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				localStorage.setItem('shopCartCouponCode', couponCode);
				const coupon_data = data.coupon_data;
				
				dispatch({
				  type: APPLY_COUPON,
				  payload: {coupon_data,addToast}
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});		
	};
}; 

// remove coupon
export const RemoveCoupon = (addToast) => {	
	return dispatch => {		
		if (addToast) {
		  addToast("Coupon has been removed successfully", {
			appearance: "error",
			autoDismiss: true
		  });
		}
		
		dispatch({
		  type: REMOVE_COUPON
		});
	};
}; 
	
	
