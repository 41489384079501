import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES, LBS_CONSTANT } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AdjustInventoryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("adjust-product-package").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("adjustPackageForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('package_id',this.props.product_package.package_id);
			
			axios.post(REST_API_END_POINT+'inventory/adjust-product-package',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById(this.props.api_message);
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("adjust-product-package").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}else{
					this.props.toggleModal();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("adjust-product-package").disabled=false;
			});
		}
	}
	
	render() {
		const qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==this.props.product.qty_type });
		const product_package = this.props.product_package;
		var quantity=product_package.quantity;
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				Remove stock 
			  </ModalHeader>
			  <AvForm
					id="adjustPackageForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Barcode</Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="package_barcode"
									name="barcode"
									type="text"
									autoComplete="off"
									disabled
									value={product_package.barcode}
								/>
							</AvGroup>
							<Input type="hidden" name="qty_type" value={qty_type.label} />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3 ">
							<Label> Quantity {product_package.variation_id=="0" ? `(${typeof qty_type!='undefined' ? qty_type.label:""})`:""}</Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="old_quantity"
									name="old_quantity"
									type="text"
									autoComplete="off"
									readOnly={true}
									value={quantity}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3 d-none">
							<Label>New Quantity {product_package.variation_id=="0" ? `(${typeof qty_type!='undefined' ? qty_type.label:""})`:""}<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9 d-none">
							<AvGroup >								
								<AvField
									id="new_qty"
									name="new_qty"
									type="number"
									autoComplete="off"
									value={quantity}
									min="0"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										step: {
											value: product_package.variation_id=="0" ? 0.001:1,
											errorMessage: product_package.variation_id=="0" ? "This value is invalid.":"Only whole numbers are allowed"
										},
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>Reason<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="inventory_adjust_reason"
									name="reason"
									type="textarea"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="adjust-product-package" >
						<IntlMessages id="customer.save" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};