import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import moment from 'moment';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddNewVehicleModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {	
			customer_id:null
		};
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			if(this.state.customer_id==null){
				swal("Warning","Please select a customer","warning");
				return;
			}
			
			document.getElementById("add-vehicle").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("vehicleForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'customer/add-vehicle',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-vehicle").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				this.setState({ customer_id:null })

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-vehicle").disabled=false;
			});

		}
	} 


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add New Vehicle
		  </ModalHeader>
		  <AvForm
				id="vehicleForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Customer<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="customer_id"
									id="customer_id"
									placeholder="--Select Customer--"
									options={this.props.customers}						
									value={this.state.customer_id}
									onChange={customer_id => { this.setState({ customer_id }) }}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Number<span className="text-danger">*</span></Label>
							  <AvField
								id="plate"
								name="no_plate"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
								
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Emirate<span className="text-danger">*</span></Label>
							  <AvField
								id="emirate"
								name="emirate"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
								
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Brand Name<span className="text-danger">*</span></Label>
							  <AvField
								id="make"
								name="vehicle_make"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
								
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Model Name<span className="text-danger">*</span></Label>
							  <AvField
								id="model"
								name="vehicle_model"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
							  />
							</AvGroup>
						</Colxx>					
					
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Year<span className="text-danger">*</span></Label>
							  <AvField
								id="vehicle_year"
								name="vehicle_year"
								type="number"
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min:{
										value: "1000",
										errorMessage: "Please enter a value greater than or equal to 1000"
									},
									max:{
										value: moment().format('YYYY'),
										errorMessage: "Please enter a value less than or equal to "+moment().format('YYYY')
									},
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Chassis Number<span className="text-danger">*</span></Label>
							  <AvField
								id="chaisis_number"
								name="chaisis_number"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-vehicle" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewVehicleModal;
